import React, { useState } from 'react';
import { Box, Button, Grid, Stack, Table, TableBody, Typography, TableCell } from '@mui/material';
import {
  MainTitle,
  NormalContentsSubTitle,
  NormalContentsTitle,
  SubTitle,
} from '../../component/style/TextStyle';
import { StyledRow } from '../../component/style/TableStyle';
import { changeDateDetail } from '../../component/commonFunction';
import {
  dashboardFlavorHook,
  dashboardProductionHook,
  dashboardRecipeHook,
} from '../../module/useEffectHooks';

export default function CurrentSummary() {
  const currentTime = new Date();

  const [flavorList, setFlavorList] = useState([]);
  const [recipeList, setRecipeList] = useState([]);
  const [productionList, setProductionList] = useState([]);

  const [refreshSwitch, setRefreshSwitch] = useState(true);

  const refreshData = () => {
    setRefreshSwitch(!refreshSwitch);
  };

  dashboardFlavorHook(setFlavorList, refreshSwitch);
  dashboardRecipeHook(setRecipeList, refreshSwitch);
  dashboardProductionHook(setProductionList, refreshSwitch);

  const productionLabelList = [
    [
      { label: '대기', keyName: 'count' },
      { label: '배합', keyName: 'count' },
      { label: '교반', keyName: 'count' },
      { label: '중탕', keyName: 'count' },
    ],
    [
      { label: '교반 종료', keyName: 'count' },
      { label: '생산팀 수령', keyName: 'count' },
      { label: '평균 생산 시간', addText: '<생산 시점>', keyName: 'time' },
      { label: '평균 생산 시간', addText: '<작업지시 시점>', keyName: 'time' },
    ],
  ];

  return (
    <Grid container direction="row" justifyContent="center" spacing="25px">
      <Grid item xs={2.5}>
        <MainTitle>향료</MainTitle>
        <Table>
          <TableBody>
            {flavorList.map((each, index) => (
              <StyledRow key={each.company}>
                <TableCell
                  align="left"
                  sx={[index === flavorList.length - 1 && { fontWeight: 700 }, { py: '10px' }]}
                >
                  {each.company}
                </TableCell>
                <TableCell
                  sx={[
                    index === flavorList.length - 1 && { fontWeight: 700 },
                    { width: '30px', py: '10px', border: '0.5px solid #C9C9C9' },
                  ]}
                >
                  {each.count}
                </TableCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs={2.5}>
        <MainTitle>보유 레시피</MainTitle>
        <Table>
          <TableBody>
            {recipeList.map((each, index) => (
              <StyledRow key={each.flavorType}>
                <TableCell
                  align="left"
                  sx={[index === recipeList.length - 1 && { fontWeight: 700 }, { py: '10px' }]}
                >
                  {each.flavorType}
                </TableCell>
                <TableCell
                  sx={[
                    index === recipeList.length - 1 && { fontWeight: 700 },
                    { width: '30px', py: '10px', border: '0.5px solid #C9C9C9' },
                  ]}
                >
                  {each.count}
                </TableCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs={7}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <MainTitle>생산 현황</MainTitle>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing="12px"
            sx={{ mb: '10px' }}
          >
            <SubTitle>{changeDateDetail(currentTime)}</SubTitle>
            <Button onClick={refreshData} sx={{ width: '80px', height: '28px' }}>
              새로고침
            </Button>
          </Stack>
        </Grid>
        <Box
          sx={{
            height: '285px',
            backgroundColor: '#F3F6FD',
            borderRadius: '12px',
            px: '10px',
            py: '18px',
            pb: '30px',
          }}
        >
          {productionLabelList.map((eachRow, index1) => (
            <Stack
              direction="row"
              justifyContent="space-around"
              key={`${eachRow[0].label} 영역`}
              sx={{ mb: '25px' }}
            >
              {eachRow.map((each, index2) => (
                <Grid container justifyContent="center" key={each.label + each.addText}>
                  <Box sx={{ width: 1 }}>
                    <NormalContentsTitle
                      sx={[
                        (index1 === 0 || index2 < 2) && { py: '7px', mb: '4px' },
                        { textAlign: 'center' },
                      ]}
                    >
                      {each.label}
                    </NormalContentsTitle>
                    {each.addText && (
                      <NormalContentsSubTitle sx={{ textAlign: 'center' }}>
                        {each.addText}
                      </NormalContentsSubTitle>
                    )}
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: '#CED8F3',
                      borderRadius: '12px',
                      border: '0.5px solid #A9A9A9',
                      height: '90px',
                      width: '90px',
                      mt: '5px',
                    }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignContent="center"
                      sx={{ height: 1 }}
                    >
                      <Typography component="div" fontSize={20} fontWeight={500}>
                        {/* if not 에러 undefined keyName으로 */}
                        {productionList.length !== 0 && (
                          <Box>{productionList[index1 * 4 + index2][each.keyName]}</Box>
                        )}
                        {index1 === 1 && index2 > 1 && (
                          <Box sx={{ fontSize: 17, mt: '-7px', fontWeight: 400 }}>hour</Box>
                        )}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Stack>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}
