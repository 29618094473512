import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Stack, Typography } from '@mui/material';
import { FixedContainer } from '../style/ContainerStyle';

/** 서브 네비게이션 */
export default function SubNavBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTab = location.pathname.split('/')[1].toLowerCase();

  const productionMenuList = [
    { label: '생산지시', link: '/Production/Instruction', detailURL: 'Instruction' },
    { label: '생산현황', link: '/Production/Status', detailURL: 'Status' },
    { label: '생산기록', link: '/Production/History', detailURL: 'History' },
  ];

  const materialMenuList = [
    { label: '첨가물', link: '/Material/Additive', detailURL: 'Additive' },
    { label: '구성물질', link: '/Material/Composition', detailURL: 'Composition' },
  ];

  const menuObj = { productionMenuList, materialMenuList };

  const goToLink = link => {
    navigate(link);
  };

  return (
    <div>
      {currentTab !== '' && currentTab !== 'recipe' && currentTab !== 'user' && (
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            borderBottom: '0.2px solid #C9C9C9',
            backgroundColor: '#F3F6FD',
            position: 'fixed',
            zIndex: 110,
          }}
        >
          <FixedContainer sx={{ backgroundColor: '#F3F6FD', py: '7px' }}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing="20px"
              sx={[currentTab === 'production' ? { pl: '70px' } : { pl: '270px' }]}
            >
              {/* 현재 탭에 따라 다른 목록을 */}
              {menuObj[`${currentTab}MenuList`].map(function (each) {
                return (
                  <Typography
                    key={each.label}
                    fontSize={16}
                    fontWeight={
                      location.pathname === each.link || location.pathname.includes(each.detailURL)
                        ? 700
                        : 400
                    }
                    onClick={() => {
                      goToLink(each.link);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    {each.label}
                  </Typography>
                );
              })}
            </Stack>
          </FixedContainer>
        </Container>
      )}
      {currentTab !== '' && currentTab !== 'recipe' && currentTab !== 'user' && (
        <Box sx={{ height: '38px' }} />
      )}
    </div>
  );
}
