import React, { useState } from 'react';
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Stack,
} from '@mui/material';
import { MainTitle } from '../../../component/style/TextStyle';
import { SearchFilterAdditive } from '../../../component/ui/SearchFilter';
import { StyledRow, StyledCell } from '../../../component/style/TableStyle';
import { additiveNumber } from '../../../component/commonVariable';
import { materialSearchHook } from '../../../module/useEffectHooks';
import Pagination from '../../../component/Pagination';
import MaterialAddEditModal from './MaterialAddEditModal';
import { changeDateDot, downloadAll } from '../../../component/commonFunction';
import MonthlyUsage from './MonthlyUsage';
import InventoryHistory from './InventoryHistory';
import { pageAuthorityCheck } from '../../../module/LoginCheck';

export default function Additive() {
  pageAuthorityCheck();

  // 검색
  const searchField = ['분류', '제조사', '이름', '코드번호'];
  const [searchFilter, setSearchFilter] = useState({
    searchFilter: '분류',
    searchInput: '',
  });

  // 정렬
  const [sortField, setSortField] = useState('Id');
  const [sortType, setSortType] = useState('▼');

  const dataTable = [
    'Id',
    '분류',
    '제조사',
    '이름',
    '코드번호',
    '비중',
    '재고',
    '메모',
    '최종수정',
    '재고 추이',
    '월별 사용량',
    '수정하기',
  ];

  // 첨가물 데이터 및 페이지
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  // 원자재 수정
  const [addEditModalSwitch, setAddEditModalSwitch] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editMaterialId, setEditMaterialId] = useState(0);

  const [refreshSwitch, setRefreshSwitch] = useState(true);

  // 사용량 & 재고 증감 확인
  const [monthlyUsageSwitch, setMonthlyUsageSwitch] = useState(false);
  const [inventoryHistorySwitch, setInventoryHistorySwitch] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  materialSearchHook(
    'material',
    searchFilter,
    sortField,
    sortType,
    setDataList,
    page,
    setTotalItem,
    refreshSwitch,
  );

  const searchFilterSearch = () => {
    setRefreshSwitch(!refreshSwitch);
  };

  const changeSorting = label => {
    if (sortField !== label) {
      setSortField(label);
    } else {
      // eslint-disable-next-line
      if (sortType === '▼') {
        setSortType('▲');
      } else {
        setSortType('▼');
      }
    }
  };

  const openAddEditModal = (mode, id) => {
    if (mode === 'add') {
      setIsEditMode(false);
      setEditMaterialId(0);
    } else if (mode === 'edit') {
      setEditMaterialId(id);
      setIsEditMode(true);
    }
    setAddEditModalSwitch(true);
  };

  const openInventoryHistoryModal = id => {
    setEditMaterialId(id);
    setInventoryHistorySwitch(true);
  };

  const openMonthlyUsageModal = id => {
    setEditMaterialId(id);
    setMonthlyUsageSwitch(true);
  };

  // 데이터 다운로드
  const download = async () => {
    try {
      if (isLoading) return;
      await downloadAll('/material/additive/download', '첨가물 목록', setIsLoading);
    } catch (error) {
      alert(`다운로드에 실패했습니다\n${error}`);
    }
  };

  return (
    <div>
      <SearchFilterAdditive
        selectList={searchField}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        searchFilterSearch={searchFilterSearch}
      />

      <Grid container direction="row" justifyContent="space-between" alignContent="center">
        <MainTitle>첨가물 리스트</MainTitle>

        <Stack direction="row" spacing={1}>
          <Button variant="outlined" onClick={download} sx={{ width: '80px', height: '30px' }}>
            {isLoading ? '다운로드중' : 'Export'}
          </Button>
          <Button onClick={() => openAddEditModal('add')} sx={{ width: '80px', height: '30px' }}>
            항목 추가
          </Button>
        </Stack>
      </Grid>

      <TableContainer component={Paper} sx={{ maxHeight: '480px', mt: '5px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {dataTable.map((each, index) => (
                <StyledCell
                  key={each}
                  onClick={index < 7 ? () => changeSorting(each) : null}
                  sx={[index < 7 && { cursor: 'pointer', userSelect: 'none' }]}
                >
                  {each}
                  {sortField === each && ` ${sortType}`}
                </StyledCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {dataList.map(each => (
              <StyledRow
                key={each.materialId}
                // eslint-disable-next-line
                sx={[
                  each.inventory <= 0 && { backgroundColor: 'rgba(100, 100, 100, 0.3) !important' },
                ]}
              >
                <StyledCell>{each.materialId}</StyledCell>
                <StyledCell>{each.category}</StyledCell>
                <StyledCell>{each.company}</StyledCell>
                <StyledCell>{each.name}</StyledCell>
                <StyledCell>{each.codeNum}</StyledCell>
                <StyledCell>{each.gravity}</StyledCell>
                <StyledCell sx={[each.inventory <= 0 && { fontWeight: 700, color: '#C21807' }]}>
                  {each.inventory}g
                </StyledCell>
                <StyledCell>{each.note}</StyledCell>
                <StyledCell>{`${each.lastEditor} / ${changeDateDot(each.updatedAt)}`}</StyledCell>
                <StyledCell>
                  <Button
                    onClick={() => openInventoryHistoryModal(each.materialId)}
                    variant="outlined"
                    sx={{ width: '70px' }}
                  >
                    확인하기
                  </Button>
                </StyledCell>
                <StyledCell>
                  <Button
                    onClick={() => openMonthlyUsageModal(each.materialId)}
                    variant="outlined"
                    sx={{ width: '70px' }}
                  >
                    확인하기
                  </Button>
                </StyledCell>
                <StyledCell>
                  <Button
                    onClick={() => openAddEditModal('edit', each.materialId)}
                    variant="outlined"
                    sx={{ width: '70px' }}
                  >
                    수정하기
                  </Button>
                </StyledCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination page={page} setPage={setPage} totalItem={totalItem} itemNumber={additiveNumber} />

      {addEditModalSwitch && (
        <MaterialAddEditModal
          modalSwitch={addEditModalSwitch}
          setModalSwitch={setAddEditModalSwitch}
          refreshSwitch={refreshSwitch}
          setRefreshSwitch={setRefreshSwitch}
          isEditMode={isEditMode}
          editMaterialId={editMaterialId}
        />
      )}

      {inventoryHistorySwitch && (
        <InventoryHistory
          modalSwitch={inventoryHistorySwitch}
          setModalSwitch={setInventoryHistorySwitch}
          materialId={editMaterialId}
        />
      )}

      {monthlyUsageSwitch && (
        <MonthlyUsage
          modalSwitch={monthlyUsageSwitch}
          setModalSwitch={setMonthlyUsageSwitch}
          materialId={editMaterialId}
        />
      )}
    </div>
  );
}
