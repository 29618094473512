import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, Stack, Grid, TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';

import { MainTitle, SubTitle } from '../../../component/style/TextStyle';
import { url } from '../../../component/commonVariable';
import { changeDateDot, sessionExpireError } from '../../../component/commonFunction';
import ConfirmModal from '../../../component/ui/ConfirmModal';
import { compositionDetailHook } from '../../../module/useEffectHooks';
import { LoginContext } from '../../../module/ContextAPI';

export default function CompositionAddEditModal({
  modalSwitch,
  setModalSwitch,
  editCompositionId,
  refreshSwitch,
  setRefreshSwitch,
  isEditMode,
}) {
  const { loginProcess } = useContext(LoginContext);
  const materialInfo = [
    [
      { label: 'Cas No', keyName: 'casNum', width: '125px', maxLength: 20 },
      { label: '유해화학물질 기준', keyName: 'hazardousStandard', width: '125px', maxLength: 45 },
    ],
    [
      { label: '이름', keyName: 'name', width: '40px', maxLength: 100 },
      { label: '메모', keyName: 'note', width: '40px', maxLength: 100 },
    ],
    [{ label: '알러지 유발 유무', keyName: 'alergy', width: '120px', maxLength: 10 }],
  ];

  // db에 ''를 위한 초기값 (undefined로 등록되거나 아니면 || ''로 일일히 등록해야 하는)
  const [compositionData, setCompositionData] = useState({
    casNum: '',
    hazardousStandard: '',
    name: '',
    alergy: '',
    note: '',
  });

  const [deleteModalSwitch, setDeleteModalSwitch] = useState(false);

  compositionDetailHook(editCompositionId, setCompositionData);

  // 구성물질 정보 수정
  const onChangeMaterialInfo = e => {
    const { name, value } = e.target;
    const tempInfoInput = { ...compositionData };
    tempInfoInput[name] = value;
    setCompositionData(tempInfoInput);
  };

  // 데이터 저장/수정
  const saveData = () => {
    const body = { ...compositionData };
    if (!compositionData.name) {
      alert('구성물질의 이름을 입력해주세요!');
    } else {
      const mode = isEditMode ? 'edit' : 'add';
      axios
        .post(`${url}/material/composition/${mode}`, body)
        .then(() => {
          alert('작업이 성공적으로 완료되었습니다');
          setRefreshSwitch(!refreshSwitch);
          modalClose();
        })
        .catch(err => {
          const isError = sessionExpireError(err);
          if (isError) {
            loginProcess('로그인X');
            return;
          }
          console.log(err);
          alert('레시피 작업을 하는 중 오류가 발생했습니다\n다시 시도해주세요');
        });
    }
  };

  const deleteRecipe = () => {
    axios.post(`${url}/material/composition/delete/${compositionData.compositionId}`).then(() => {
      alert('삭제가 성공적으로 완료되었습니다');
      setRefreshSwitch(!refreshSwitch);
      modalClose();
    });
  };

  // 삭제 모달
  const openDeleteModal = () => {
    setDeleteModalSwitch(true);
  };

  // 모달 닫기
  const modalClose = () => {
    setModalSwitch(false);
    setRefreshSwitch(!refreshSwitch);
  };

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 950,
            height: 280,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 60px 50px 60px',
            outline: 'none',
            overflowY: 'scroll',
          }}
        >
          <Typography align="center" fontSize={28} fontWeight={600}>
            구성물질 {isEditMode ? '수정' : '추가'}
          </Typography>

          <Box sx={{ height: '1px', backgroundColor: '#C9C9C9', my: '20px' }} />

          {/* 레시피 기본 정보 부분 */}
          <Stack direction="row" spacing="10px">
            <MainTitle sx={{ fontSize: '22px' }}>구성물질 정보</MainTitle>
            {isEditMode && (
              <SubTitle sx={{ pt: '5px' }}>{`최종 수정 : ${changeDateDot(
                compositionData.updatedAt,
              )} / ${compositionData.lastEditor}`}</SubTitle>
            )}
          </Stack>

          <Grid container direction="row" justifyContent="center" spacing="20px">
            {materialInfo.map(eachColumn => (
              <Grid item xs={4} key={`${eachColumn[0].label}_grid`}>
                {eachColumn.map(each => (
                  <Stack
                    direction="row"
                    spacing="10px"
                    key={each.label}
                    sx={{ mb: '5px' }}
                    alignItems="center"
                  >
                    <Box sx={{ width: each.width }}>{each.label}</Box>
                    <TextField
                      name={each.keyName}
                      fullWidth={each.label === '메모' || each.label === '이름'}
                      value={compositionData[each.keyName] || ''}
                      onChange={e => onChangeMaterialInfo(e)}
                      type={each.label === '재고' ? 'number' : ''}
                      inputProps={{
                        maxLength: each.maxLength,
                        style: { padding: '7px 0px 7px 13px' },
                      }}
                    />
                  </Stack>
                ))}
              </Grid>
            ))}
          </Grid>

          {/* 최하단 확인 / 취소 */}
          <Grid container justifyContent="center" sx={{ mt: '40px' }}>
            <Button onClick={saveData} sx={{ width: '100px', height: '30px' }}>
              {isEditMode ? '수정하기' : '추가하기'}
            </Button>
            <Button
              onClick={modalClose}
              color="cancel"
              sx={{ width: '100px', height: '30px', color: '#FFF', ml: '10px' }}
            >
              취소
            </Button>

            {isEditMode && (
              <Button
                onClick={openDeleteModal}
                color="red"
                sx={{ width: '100px', height: '30px', color: '#FFF', ml: '10px' }}
              >
                삭제
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>

      {deleteModalSwitch && (
        <ConfirmModal
          modalSwitch={deleteModalSwitch}
          setModalSwitch={setDeleteModalSwitch}
          title="정말 삭제하시겠습니까?"
          contents="구성물질의 모든 정보가 삭제됩니다"
          func={deleteRecipe}
        />
      )}
    </div>
  );
}

CompositionAddEditModal.defaultProps = {
  isEditMode: false,
  modalSwitch: true,
  setModalSwitch: () => {},
  editCompositionId: 0,
  refreshSwitch: true,
  setRefreshSwitch: () => {},
};

CompositionAddEditModal.propTypes = {
  isEditMode: PropTypes.bool,
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  editCompositionId: PropTypes.number,
  refreshSwitch: PropTypes.bool,
  setRefreshSwitch: PropTypes.func,
};
