import moment from 'moment';
import 'moment/locale/ko';
import axios from 'axios';
import { utils, writeFile } from 'xlsx';
import { url as apiURL } from './commonVariable';

/** s3 url로 바꿔주는 함수 */
export const backgroundURL = (url, isMobile) => {
  let answer;
  if (isMobile) {
    const newUrl = `${url.split('.')[0]}_mob.${url.split('.')[1]}`;
    answer = `url(https://thelineimage.s3.ap-northeast-2.amazonaws.com/${newUrl})`;
  } else {
    answer = `url(https://thelineimage.s3.ap-northeast-2.amazonaws.com/${url})`;
  }
  return answer;
};

/** 세 자리 마다 콤마를 추가 */
export const addComma = input => {
  let newInput = input;

  if (input) {
    newInput = input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return newInput;
};

/** 콤마 삭제 */
export const removeComma = input => {
  let newInput = input;
  if (input) {
    newInput = input.replace(/,/g, '');
  }
  return newInput;
};

/** 날짜 형 변환 YYYY.MM.DD */
export const changeDateDot = date => {
  let publishDate;
  if (date) {
    publishDate = moment(date).format('YYYY.MM.DD');
  }
  return publishDate;
};

/** 날짜 형 변환 YYYY-MM-DD */
export const changeDateDash = date => {
  let publishDate;
  if (date) {
    publishDate = moment(date).format('YYYY-MM-DD');
  }
  return publishDate;
};

/** 날짜 형 변환 OO월 OO일(요일) */
export const changeDateKor = date => {
  let publishDate;
  if (date) {
    publishDate = moment(date).format('MMMM Do (dd)');
  }
  return publishDate;
};

/** 날짜, 시간, 분, 요일 */
export const changeDateDetail = date => {
  let publishDate;
  if (date) {
    publishDate = moment(date).format('MMMM Do (dd) a h:mm:ss');
  }
  return publishDate;
};

/** 날짜, 요일 */
export const changeDateDayName = date => {
  let publishDate;
  if (date) {
    publishDate = moment(date).format('MMMM Do (dd)');
  }
  return publishDate;
};

/** 시간만 변환 */
export const changeDateTime = date => {
  let publishDate;
  if (date) {
    publishDate = moment(date).format('HH:mm');
  }
  return publishDate;
};

/** 날짜, 시간, 분 */
export const changeDateMinute = date => {
  let publishDate;
  if (date) {
    publishDate = moment(date).format('YYYY-MM-DD HH:mm');
  }
  return publishDate;
};

/** n의 자리 수로 나누고 반올림 후 콤마 */
export const RoundNAddComma = (num, digit) => {
  const divider = 10 ** digit;
  let answer;
  answer = parseInt(num, 10);
  answer = Math.round(answer / divider) * divider;
  answer = addComma(answer);

  return answer;
};

/** 세션 만료 오류 */
export const sessionExpireError = err => {
  let isError = false;
  if (err.response.data.message.includes('세션')) {
    alert('세션이 만료되었습니다\n다시 로그인해주세요');
    isError = true;
  }
  return isError;
};

/** 다운로드 */
export async function downloadAll(detailUrl, fileName, setIsLoading) {
  setIsLoading(true);
  await axios
    .get(`${apiURL}${detailUrl}`)
    .then(result => {
      const ws = utils.json_to_sheet(result.data);

      // 새 워크북을 생성하고 워크시트를 추가
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, fileName);

      // 워크북을 XLSX 파일로 쓰고 다운로드
      writeFile(wb, `${fileName}.xlsx`);
      setIsLoading(false);
      // return result.data;
    })
    .catch(err => {
      console.error(err);
      setIsLoading(false);
      throw new Error(err);
    });
}
