import React, { useState } from 'react';
import { Table, TableHead, TableBody, TableRow, Grid, Button } from '@mui/material';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { DocStyledRow, DocStyledCell } from '../style/TableStyle';
import { MainTitle } from '../style/TextStyle';
import { workCompletionHook } from '../../module/useEffectHooks';
import { changeDateMinute, changeDateDash, changeDateTime } from '../commonFunction';

export default function WorkCompletion({ selectedProductionId }) {
  const recipeInfo = ['지시날짜', '작업번호', '중탕', '작업 지시 생산량', '교반용기'];
  const recipeInfo2 = ['분류', '구분/입,폐', '제품명', '제품용량', '레시피넘버'];

  // eslint-disable-next-line
  const productionHistory = [
    '생산지시',
    '배합',
    '교반',
    '중탕',
    '교반 종료',
    '생산팀 수령',
    '완료',
  ];

  const [dataList, setDataList] = useState({});
  const [recipeDetailList, setRecipeDetailList] = useState([]);

  const recipeDetailLabel = [
    '종류',
    '용량',
    `생산량 변동\n${
      dataList.changeMaterial ? `(${dataList.changeMaterial}로 인한 변동)` : '(변동 없음)'
    }`,
    '작업량 수기입력',
  ];

  workCompletionHook(selectedProductionId, setDataList, setRecipeDetailList);

  const captureImg = () => {
    const capDiv = document.getElementById('captureDiv');
    html2canvas(capDiv).then(canvas => {
      const link = document.createElement('a');
      link.download = `작업내역서_${dataList.workWeek}-${dataList.workNumber}_${dataList.name}`;
      link.href = canvas.toDataURL();
      document.body.appendChild(link);
      link.click();
    });
  };

  const capturePdf = () => {
    html2canvas(document.getElementById('captureDiv'), { scale: 9 }).then(function (canvas) {
      const filename = `작업내역서_${dataList.workWeek}-${dataList.workNumber}_${dataList.name}.pdf`;
      // eslint-disable-next-line
      const doc = new jsPDF('p', 'mm', 'a4');

      const imgData = canvas.toDataURL('image/jpeg');
      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(filename);
    });
  };

  return (
    <div>
      <MainTitle>
        {dataList.currentState === '작업완료' ? '작업완료내역서' : '작업지시서'}
      </MainTitle>

      {/* 레시피 기본 정보 부분 */}
      <Table sx={{ border: '2px solid #333333', mt: '10px' }}>
        <TableHead>
          <TableRow>
            {recipeInfo.map(each => (
              <DocStyledCell key={each}>{each}</DocStyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell sx={{ width: '135px' }}>
              {changeDateMinute(dataList.instructionAt)}
            </DocStyledCell>
            <DocStyledCell sx={{ width: '90px' }}>
              {`${dataList.workWeek}-${dataList.workNumber}`}
            </DocStyledCell>
            <DocStyledCell>{dataList.boiling}</DocStyledCell>
            <DocStyledCell>
              {`${dataList.totalWeight}g\n약 ${Math.floor(
                dataList.totalWeight / (dataList.containerVolume * 1.1),
              )}개`}
            </DocStyledCell>
            <DocStyledCell>{dataList.barrel}</DocStyledCell>
          </DocStyledRow>
        </TableBody>
        <TableHead>
          <TableRow>
            <DocStyledCell colSpan={5}>메모</DocStyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell colSpan={5}>{dataList.productionNote}</DocStyledCell>
          </DocStyledRow>
        </TableBody>
        <TableHead>
          <TableRow>
            {recipeInfo2.map(each => (
              <DocStyledCell key={each}>{each}</DocStyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell width="25%">{dataList.category}</DocStyledCell>
            <DocStyledCell width="16.6%">{dataList.inhaleType}</DocStyledCell>
            <DocStyledCell width="16.6%">{dataList.name}</DocStyledCell>
            <DocStyledCell width="16.6%">{dataList.containerVolume}ml</DocStyledCell>
            <DocStyledCell width="25%">{dataList.recipeNum}</DocStyledCell>
          </DocStyledRow>
        </TableBody>
      </Table>
      {/* 레시피 상세 부분 */}
      <Table sx={{ border: '2px solid #333333', mt: '20px' }}>
        <TableHead>
          <TableRow>
            {recipeDetailLabel.map(each => (
              <DocStyledCell key={each}>{each}</DocStyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {recipeDetailList.map(each => (
            <DocStyledRow key={each.name}>
              <DocStyledCell width="25%">{each.name}</DocStyledCell>
              <DocStyledCell width="25%">{each.materialWeight}g</DocStyledCell>
              <DocStyledCell width="25%">
                {each.changeMaterialWeight && `${each.changeMaterialWeight}g`}
              </DocStyledCell>
              <DocStyledCell width="25%">&nbsp;</DocStyledCell>
            </DocStyledRow>
          ))}
        </TableBody>
      </Table>

      {/* 출고자 무게체크 (작업완료 시) */}
      {dataList.currentState === '작업완료' && (
        <Grid container justifyContent="space-between" sx={{ mt: '20px' }}>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Table sx={{ border: '2px solid #333333', mt: '20px' }}>
              <TableBody>
                <DocStyledRow>
                  <DocStyledCell sx={{ backgroundColor: '#EEEEEE !important', width: 0.5 }}>
                    출고자 무게체크
                  </DocStyledCell>
                  <DocStyledCell>
                    {dataList.pickupWeight ? `${dataList.pickupWeight}g` : '출고전'}
                  </DocStyledCell>
                </DocStyledRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      )}

      {/* 작업 히스토리 */}
      <Table sx={{ border: '2px solid #333333', mt: '20px' }}>
        <TableHead>
          <TableRow>
            {productionHistory.map(each => (
              <DocStyledCell key={each}>{each}</DocStyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell width="14.2%">{dataList.instructionBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.combinationBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.stirringBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.boilingBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.endBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.relaseBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.pickupBy}</DocStyledCell>
          </DocStyledRow>
          <DocStyledRow>
            <DocStyledCell>{changeDateDash(dataList.instructionAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.combinationAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.stirringAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.boilingAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.endAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.relaseAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.pickupAt)}</DocStyledCell>
          </DocStyledRow>
          <DocStyledRow>
            <DocStyledCell>{changeDateTime(dataList.instructionAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.combinationAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.stirringAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.boilingAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.endAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.relaseAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.pickupAt)}</DocStyledCell>
          </DocStyledRow>
        </TableBody>
      </Table>
      {/* 특이사항 */}
      <Table sx={{ border: '2px solid #333333', mt: '20px' }}>
        <TableHead>
          <TableRow>
            <DocStyledCell colSpan={5}>특이사항</DocStyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell colSpan={5}> &nbsp;</DocStyledCell>
          </DocStyledRow>
        </TableBody>
      </Table>

      <Grid container direction="row" justifyContent="flex-end" sx={{ mt: '20px' }}>
        <Button
          color="secondary"
          data-html2canvas-ignore="true"
          onClick={capturePdf}
          sx={{ width: 100, height: 30, mr: '10px' }}
        >
          PDF로 저장
        </Button>
        <Button data-html2canvas-ignore="true" onClick={captureImg} sx={{ width: 100, height: 30 }}>
          PNG로 저장
        </Button>
      </Grid>
    </div>
  );
}

WorkCompletion.defaultProps = {
  selectedProductionId: 0,
};

WorkCompletion.propTypes = {
  selectedProductionId: PropTypes.number,
};
