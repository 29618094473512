import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, Stack, Grid, TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { url } from '../../component/commonVariable';
import ConfirmModal from '../../component/ui/ConfirmModal';
import DropDown from '../../component/ui/DropDown';

export default function UserAddEditModal({
  modalSwitch,
  setModalSwitch,
  editData,
  refreshSwitch,
  setRefreshSwitch,
  isEditMode,
}) {
  const materialInfo = [
    [
      { label: '아이디', keyName: 'id', width: '60px', maxLength: 30 },
      { label: '이름', keyName: 'name', width: '60px', maxLength: 10 },
    ],
    [
      { label: '부서', keyName: 'department', width: '60px', maxLength: 20 },
      { label: '권한', keyName: 'authority', width: '60px', maxLength: 10 },
    ],
    [{ label: '메모', keyName: 'note', width: '60px', maxLength: 100 }],
  ];

  const authorityList = ['A', 'B', 'C', 'D'];

  // db에 ''를 위한 초기값 (undefined로 등록되거나 아니면 || ''로 일일히 등록해야 하는)
  const defaultValue = {
    id: '',
    name: '',
    department: '',
    authority: 'C',
    note: '',
  };
  const [userData, setUserData] = useState(isEditMode ? editData : defaultValue);

  const [deleteModalSwitch, setDeleteModalSwitch] = useState(false);

  // 회원 정보 수정
  const onChangeUserInfo = e => {
    const { name, value } = e.target;
    const tempInfoInput = { ...userData };
    tempInfoInput[name] = value;
    setUserData(tempInfoInput);
  };

  // 회원 정보 수정
  const onChangeAuthority = e => {
    const tempInfoInput = { ...userData };
    tempInfoInput.authority = e;
    setUserData(tempInfoInput);
  };

  // 데이터 저장/수정
  const saveData = () => {
    const body = { ...userData };

    if (!userData.id) {
      alert('회원 아이디를 입력해주세요');
    } else {
      const mode = isEditMode ? 'edit' : 'add';
      axios.post(`${url}/user/account/${mode}`, body).then(() => {
        alert('작업이 성공적으로 완료되었습니다');
        setRefreshSwitch(!refreshSwitch);
        modalClose();
      });
    }
  };

  const deleteRecipe = () => {
    axios.post(`${url}/user/account/delete/${userData.userId}`).then(() => {
      alert('삭제가 성공적으로 완료되었습니다');
      setRefreshSwitch(!refreshSwitch);
      modalClose();
    });
  };

  // 삭제 모달
  const openDeleteModal = () => {
    setDeleteModalSwitch(true);
  };

  // 모달 닫기
  const modalClose = () => {
    setModalSwitch(false);
    setRefreshSwitch(!refreshSwitch);
  };

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 800,
            height: 280,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 60px 50px 60px',
            outline: 'none',
            overflowY: 'scroll',
          }}
        >
          <Typography align="center" fontSize={28} fontWeight={600}>
            계정 {isEditMode ? '수정' : '추가'}
          </Typography>

          <Box sx={{ height: '1px', backgroundColor: '#C9C9C9', mt: '20px', mb: '30px' }} />

          <Grid container direction="row" justifyContent="center" spacing="20px">
            {materialInfo.map(eachColumn => (
              <Grid item xs={4} key={`${eachColumn[0].label}_grid`}>
                {eachColumn.map(each => (
                  <Stack
                    direction="row"
                    spacing="10px"
                    key={each.label}
                    sx={{ mb: '5px' }}
                    alignItems="center"
                  >
                    <Box sx={{ width: each.width }}>{each.label}</Box>
                    {each.label === '권한' ? (
                      <DropDown
                        selectList={authorityList}
                        value={userData.authority}
                        onChange={e => onChangeAuthority(e)}
                        fullWidth
                      />
                    ) : (
                      <TextField
                        name={each.keyName}
                        fullWidth
                        value={userData[each.keyName] || ''}
                        onChange={e => onChangeUserInfo(e)}
                        inputProps={{
                          maxLength: each.maxLength,
                          style: { padding: '7px 0px 7px 13px' },
                        }}
                      />
                    )}
                  </Stack>
                ))}
              </Grid>
            ))}
          </Grid>
          <p>* 초기 비밀번호는 0000으로 설정됩니다</p>

          {/* 최하단 확인 / 취소 */}
          <Grid container justifyContent="center" sx={{ mt: '40px' }}>
            <Button onClick={saveData} sx={{ width: '100px', height: '30px' }}>
              {isEditMode ? '수정하기' : '추가하기'}
            </Button>
            <Button
              onClick={modalClose}
              color="cancel"
              sx={{ width: '100px', height: '30px', color: '#FFF', ml: '10px' }}
            >
              취소
            </Button>

            {isEditMode && (
              <Button
                onClick={openDeleteModal}
                color="red"
                sx={{ width: '100px', height: '30px', color: '#FFF', ml: '10px' }}
              >
                삭제
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>

      {deleteModalSwitch && (
        <ConfirmModal
          modalSwitch={deleteModalSwitch}
          setModalSwitch={setDeleteModalSwitch}
          title="정말 삭제하시겠습니까?"
          contents="계정이 삭제됩니다"
          func={deleteRecipe}
        />
      )}
    </div>
  );
}

UserAddEditModal.defaultProps = {
  isEditMode: false,
  modalSwitch: true,
  setModalSwitch: () => {},
  editData: {},
  refreshSwitch: true,
  setRefreshSwitch: () => {},
};

UserAddEditModal.propTypes = {
  isEditMode: PropTypes.bool,
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  // eslint-disable-next-line
  editData: PropTypes.object,
  refreshSwitch: PropTypes.bool,
  setRefreshSwitch: PropTypes.func,
};
