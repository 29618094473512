import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select } from '@mui/material';

/** 드롭 다운 컴포넌트  - selectList => 목록을 담은 배열
 * 상위 컴포넌트에서 관리할 state는 value, onChange에 주입 */
export default function DropDown({ selectList, value, onChange, width, fullWidth }) {
  return (
    <FormControl fullWidth={fullWidth}>
      <Select
        // (optional) name은 다수의 input안에서 활용 시, 다수 input 관리를 위한 name
        value={value}
        // autoWidth
        onChange={e => {
          onChange(e.target.value);
        }}
        SelectDisplayProps={{
          style: {
            padding: '7px 40px 7px 15px',
          },
        }}
        sx={{ width, minWidth: '100px', textAlign: 'left' }}
      >
        {selectList.map(function (eachdata) {
          return (
            <MenuItem key={eachdata} value={eachdata}>
              {eachdata}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

DropDown.defaultProps = {
  selectList: [],
  value: '',
  onChange: () => {},
  width: '',
  fullWidth: false,
};

DropDown.propTypes = {
  selectList: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.string,
  fullWidth: PropTypes.bool,
};
