import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Table, TableHead, TableBody, TableRow, Grid, Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { DocStyledRow, DocStyledCell } from '../../../component/style/TableStyle';
import { MainTitle } from '../../../component/style/TextStyle';
import { workCompletionHook } from '../../../module/useEffectHooks';
import {
  changeDateMinute,
  changeDateDash,
  changeDateTime,
  sessionExpireError,
} from '../../../component/commonFunction';
import { url } from '../../../component/commonVariable';
import { LoginContext } from '../../../module/ContextAPI';

export default function ChangeWorkCompletion({
  selectedProductionId,
  setChangeWorkCompletionSwitch,
  setRefreshSwitch,
}) {
  const { loginProcess } = useContext(LoginContext);

  const recipeInfo = ['지시날짜', '작업번호', '중탕', '생산량', '교반용기'];
  const recipeInfo2 = ['분류', '구분/입,폐', '제품명', '제품용량', '레시피넘버'];

  const recipeDetailLabel = ['종류', '용량', '생산량 변동 입력\n(0 입력시 변동 내역 삭제'];

  // eslint-disable-next-line
  const productionHistory = [
    '생산지시',
    '배합',
    '교반',
    '중탕',
    '교반 종료',
    '생산팀 수령',
    '완료',
  ];

  const [dataList, setDataList] = useState({});
  const [recipeDetailList, setRecipeDetailList] = useState([]);

  // 재료 양 변경
  const [changeMaterialInput, setChangeMaterialInput] = useState(0);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  workCompletionHook(selectedProductionId, setDataList, setRecipeDetailList);

  const selectMaterial = (data, ind) => {
    setSelectedData(data);
    setSelectedMaterial(ind);
  };

  const cancelSelecting = () => {
    setSelectedMaterial(null);
  };

  const onChangeMaterialInput = e => {
    setChangeMaterialInput(e.target.value);
  };

  const saveChange = () => {
    if (changeMaterialInput < 0) {
      alert('올바른 값을 입력해주세요');
    } else {
      const body = {
        material_materialId: selectedData.material_materialId,
        name: selectedData.name,
        productionId: selectedProductionId,
        changeAmount: changeMaterialInput,
      };
      axios
        .post(`${url}/production/changeAmount`, body)
        .then(res => {
          // console.log(res.data);
          alert(res.data);
          setChangeWorkCompletionSwitch(false);
          setRefreshSwitch(prev => !prev);
        })
        .catch(err => {
          const isError = sessionExpireError(err);
          if (isError) {
            loginProcess('로그인X');
            return;
          }
          console.log(err);
          alert('레시피 작업을 하는 중 오류가 발생했습니다\n다시 시도해주세요');
        });
    }
  };

  return (
    <div>
      <MainTitle>
        {dataList.currentState === '작업완료' ? '작업완료내역서' : '작업지시서 변동 수정'}
      </MainTitle>

      {/* 레시피 기본 정보 부분 */}
      <Table sx={{ border: '2px solid #333333', mt: '10px' }}>
        <TableHead>
          <TableRow>
            {recipeInfo.map(each => (
              <DocStyledCell key={each}>{each}</DocStyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell sx={{ width: '135px' }}>
              {changeDateMinute(dataList.instructionAt)}
            </DocStyledCell>
            <DocStyledCell sx={{ width: '90px' }}>
              {`${dataList.workWeek}-${dataList.workNumber}`}
            </DocStyledCell>
            <DocStyledCell>{dataList.boiling}</DocStyledCell>
            <DocStyledCell>
              {`${dataList.totalWeight}g\n약 ${Math.floor(
                dataList.totalWeight / (dataList.containerVolume * 1.1),
              )}개`}
            </DocStyledCell>
            <DocStyledCell>{dataList.barrel}</DocStyledCell>
          </DocStyledRow>
        </TableBody>
        <TableHead>
          <TableRow>
            <DocStyledCell colSpan={5}>메모</DocStyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell colSpan={5}>{dataList.productionNote}</DocStyledCell>
          </DocStyledRow>
        </TableBody>
        <TableHead>
          <TableRow>
            {recipeInfo2.map(each => (
              <DocStyledCell key={each}>{each}</DocStyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell width="25%">{dataList.category}</DocStyledCell>
            <DocStyledCell width="16.6%">{dataList.inhaleType}</DocStyledCell>
            <DocStyledCell width="16.6%">{dataList.name}</DocStyledCell>
            <DocStyledCell width="16.6%">{dataList.containerVolume}ml</DocStyledCell>
            <DocStyledCell width="25%">{dataList.recipeNum}</DocStyledCell>
          </DocStyledRow>
        </TableBody>
      </Table>
      {/* 레시피 상세 부분 */}
      <Table sx={{ border: '2px solid #333333', mt: '20px' }}>
        <TableHead>
          <TableRow>
            {recipeDetailLabel.map(each => (
              <DocStyledCell key={each}>{each}</DocStyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {recipeDetailList.map((each, index) => (
            <DocStyledRow key={each.name}>
              <DocStyledCell width="25%">{each.name}</DocStyledCell>
              <DocStyledCell width="25%">{each.materialWeight}g</DocStyledCell>
              <DocStyledCell width="50%">
                {selectedMaterial === index ? (
                  <Grid container justifyContent="center" alignContent="center">
                    <TextField
                      value={changeMaterialInput}
                      onChange={onChangeMaterialInput}
                      type="number"
                      sx={{ width: '100px', mr: '5px' }}
                    />
                    <Button onClick={saveChange}>완료</Button>
                    <Button
                      onClick={cancelSelecting}
                      color="cancel"
                      sx={{ color: '#FFFFFF', ml: '5px' }}
                    >
                      취소
                    </Button>
                  </Grid>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => selectMaterial(each, index)}
                    sx={{ display: each.name === '합계' && 'none' }}
                  >
                    선택
                  </Button>
                )}
              </DocStyledCell>
            </DocStyledRow>
          ))}
        </TableBody>
      </Table>

      {/* 출고자 무게체크 (작업완료 시) */}
      {dataList.currentState === '작업완료' && (
        <Grid container justifyContent="space-between" sx={{ mt: '20px' }}>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Table sx={{ border: '2px solid #333333', mt: '20px' }}>
              <TableBody>
                <DocStyledRow>
                  <DocStyledCell sx={{ backgroundColor: '#EEEEEE !important', width: 0.5 }}>
                    출고자 무게체크
                  </DocStyledCell>
                  <DocStyledCell>
                    {dataList.pickupWeight ? `${dataList.pickupWeight}g` : '출고전'}
                  </DocStyledCell>
                </DocStyledRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      )}

      {/* 작업 히스토리 */}
      <Table sx={{ border: '2px solid #333333', mt: '20px' }}>
        <TableHead>
          <TableRow>
            {productionHistory.map(each => (
              <DocStyledCell key={each}>{each}</DocStyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell width="14.2%">{dataList.instructionBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.combinationBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.stirringBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.boilingBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.endBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.relaseBy}</DocStyledCell>
            <DocStyledCell width="14.2%">{dataList.pickupBy}</DocStyledCell>
          </DocStyledRow>
          <DocStyledRow>
            <DocStyledCell>{changeDateDash(dataList.instructionAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.combinationAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.stirringAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.boilingAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.endAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.relaseAt)}</DocStyledCell>
            <DocStyledCell>{changeDateDash(dataList.pickupAt)}</DocStyledCell>
          </DocStyledRow>
          <DocStyledRow>
            <DocStyledCell>{changeDateTime(dataList.instructionAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.combinationAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.stirringAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.boilingAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.endAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.relaseAt)}</DocStyledCell>
            <DocStyledCell>{changeDateTime(dataList.pickupAt)}</DocStyledCell>
          </DocStyledRow>
        </TableBody>
      </Table>
      {/* 특이사항 */}
      <Table sx={{ border: '2px solid #333333', mt: '20px' }}>
        <TableHead>
          <TableRow>
            <DocStyledCell colSpan={5}>특이사항</DocStyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell colSpan={5}> &nbsp;</DocStyledCell>
          </DocStyledRow>
        </TableBody>
      </Table>
    </div>
  );
}

ChangeWorkCompletion.defaultProps = {
  selectedProductionId: 0,
  setChangeWorkCompletionSwitch: () => {},
  setRefreshSwitch: () => {},
};

ChangeWorkCompletion.propTypes = {
  selectedProductionId: PropTypes.number,
  setChangeWorkCompletionSwitch: PropTypes.func,
  setRefreshSwitch: PropTypes.func,
};
