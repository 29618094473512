import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TableRow } from '@mui/material';

/** 공통적으로 쓰고 있는 TableCell  */
export const StyledCell = styled(TableCell)(({ theme }) => ({
  minWidth: '30px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#888888',
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 600,
    padding: '10px 0px',
    borderSpacing: 0,
    borderCollaspe: 'collapse',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '8px 5px',
  },
}));

/** 공통적으로 쓰고 있는 TableRow  */
export const StyledRow = styled(TableRow)(() => ({
  border: '0.5px solid #C9C9C9',
  // '&:nth-of-type(1)': {
  //   borderTop: '1px solid #333333',
  // },
  '&:nth-of-type(odd)': {
    backgroundColor: '#FCFCFF',
  },
}));

/** 공통적으로 쓰고 있는 TableRow  */
export const DocStyledRow = styled(TableRow)(({ theme }) => ({
  border: '0.5px solid #C9C9C9',
  // '&:nth-of-type(1)': {
  //   borderTop: '1px solid #333333',
  // },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

/** 공통적으로 쓰고 있는 TableCell  */
export const DocStyledCell = styled(TableCell)(() => ({
  whiteSpace: 'pre-wrap',
  lineHeight: '20px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    fontSize: 12,
    fontWeight: 500,
    padding: '4px 0px',
    border: '1px solid #333333',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11.5,
    padding: '4px 5px',
    border: '1px solid #333333',
    backgroundColor: '#FFF',
  },
}));
