import React, { useState, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Stack, TextField } from '@mui/material';
import { url } from '../../../component/commonVariable';
import ConfirmModal from '../../../component/ui/ConfirmModal';
import WorkCompletion from '../../../component/ui/WorkCompletion';
import PaperModalForm from '../../../component/ui/PaperModalForm';
import { LoginContext } from '../../../module/ContextAPI';
import ChangeWorkCompletion from './ChangeWorkCompletion';
import { sessionExpireError } from '../../../component/commonFunction';

export default function StatusModal({
  modalSwitch,
  setModalSwitch,
  selectedData,
  setRefreshSwitch,
}) {
  const { userAuthority, loginProcess } = useContext(LoginContext);
  // 확인
  const [confirmModalSwitch, setConfirmModalSwitch] = useState(false);
  const [confirmState, setConfirmState] = useState('이전');

  // 교반팀 출고 무게 입력
  const [pickupWeight, setPickupWeight] = useState(0);

  // 작업내역서
  const [workCompletionSwitch, setWorkCompletionSwitch] = useState(false);
  const [selectedProductionId, setSelectedProductionId] = useState(0);

  // 작업내역서 변동량 기입
  const [changeWorkCompletionSwitch, setChangeWorkCompletionSwitch] = useState(false);

  const modalClose = () => {
    setModalSwitch(false);
  };

  // 다음 단계로 이동
  const nextStep = () => {
    const body = {
      pickupWeight,
      currentInd: selectedData.statusIndex,
      productionId: selectedData.productionId,
      currentState: selectedData.currentState,

      recipeId: selectedData.recipeId,
      totalWeight: selectedData.totalWeight,
      changeTotalWeight: selectedData.changeTotalWeight,
    };
    axios
      .post(`${url}/production/status/next`, body)
      .then(() => {
        alert('작업이 성공적으로 완료되었습니다');
        setRefreshSwitch(prev => !prev);
        modalClose();
      })
      .catch(err => {
        const isError = sessionExpireError(err);
        if (isError) {
          loginProcess('로그인X');
          return;
        }
        console.log(err);
        alert('레시피 작업을 하는 중 오류가 발생했습니다\n다시 시도해주세요');
      });
  };

  // 이전 단계로 이동
  const prevStep = () => {
    const body = {
      currentInd: selectedData.statusIndex,
      productionId: selectedData.productionId,
      currentState: selectedData.currentState,

      recipeId: selectedData.recipeId,
      totalWeight: selectedData.totalWeight,
      changeTotalWeight: selectedData.changeTotalWeight,
    };
    axios
      .post(`${url}/production/status/prev`, body)
      .then(() => {
        alert('작업이 성공적으로 완료되었습니다');
        setRefreshSwitch(prev => !prev);
        modalClose();
      })
      .catch(err => {
        const isError = sessionExpireError(err);
        if (isError) {
          loginProcess('로그인X');
          return;
        }
        console.log(err);
        alert('레시피 작업을 하는 중 오류가 발생했습니다\n다시 시도해주세요');
      });
  };

  const openWorkCompletion = () => {
    setSelectedProductionId(selectedData.productionId);
    setWorkCompletionSwitch(true);
  };

  const openChangeWorkCompletion = () => {
    setSelectedProductionId(selectedData.productionId);
    setChangeWorkCompletionSwitch(true);
  };

  const onChangePickupWeight = e => {
    setPickupWeight(e.target.value);
  };

  // 중탕 건너뛰기
  const skipBoiling = () => {
    const body = {
      pickupWeight,
      currentInd: selectedData.statusIndex,
      productionId: selectedData.productionId,
      currentState: selectedData.currentState,
    };
    axios
      .post(`${url}/production/skipBoiling`, body)
      .then(() => {
        alert('작업이 성공적으로 완료되었습니다');
        setRefreshSwitch(prev => !prev);
        modalClose();
      })
      .catch(err => {
        const isError = sessionExpireError(err);
        if (isError) {
          loginProcess('로그인X');
          return;
        }
        console.log(err);
        alert('레시피 작업을 하는 중 오류가 발생했습니다\n다시 시도해주세요');
      });
  };

  const showConfirmModal = step => {
    if (selectedData.currentState === '생산팀 수령' && step === '다음') {
      if (pickupWeight === 0) {
        alert('수령 무게를 입력해주세요!');
      } else {
        setConfirmState(step);
        setConfirmModalSwitch(true);
      }
    } else {
      setPickupWeight(0);
      setConfirmState(step);
      setConfirmModalSwitch(true);
    }
  };

  const prevLabel = `이전 단계로 이동${selectedData.currentState === '대기' ? ' (삭제)' : ''}`;
  // 함수 먼저 정의 필요하여 아래에
  const defaultMenuList = [
    {
      // 대기인 경우에만 작업 시작 (수정 요청)
      label: selectedData.currentState === '대기' ? '작업 시작' : '작업 완료 (다음 단계로 이동)',
      func: () => showConfirmModal('다음'),
    },
    { label: prevLabel, func: () => showConfirmModal('이전') },
    { label: '작업지시서', func: openWorkCompletion },
  ];

  const menuList =
    selectedData.currentState === '교반 종료' && userAuthority === 'C'
      ? defaultMenuList.splice(1)
      : defaultMenuList;

  if (selectedData.currentState === '교반') {
    menuList.push({ label: '중탕 건너뛰기', func: skipBoiling });
  }
  if (selectedData.currentState === '배합') {
    menuList.push({ label: '생산량 변동 입력', func: openChangeWorkCompletion });
  }

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 350,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            outline: 'none',
          }}
        >
          <Box sx={{ backgroundColor: '#24408E', py: '11px', px: '15px' }}>
            <Typography fontSize={15} fontWeight={600} color="#FFF">
              {`${selectedData.currentState} (${selectedData.workWeek}-${
                selectedData.workNumber
              } / ${selectedData.inhaleType}${selectedData.name} / ${
                selectedData.changeTotalWeight
                  ? `(생산량 변동) ${selectedData.changeTotalWeight}`
                  : selectedData.totalWeight
              }g)`}
            </Typography>
          </Box>

          {selectedData.currentState === '생산팀 수령' && (
            <Stack direction="row" alignItems="center" sx={{ pl: '15px', py: '5px' }}>
              <Box sx={{ width: '60px', fontSize: 13 }}>수령무게 :</Box>
              <TextField value={pickupWeight} onChange={onChangePickupWeight} type="number" />
            </Stack>
          )}

          <Box>
            {menuList.map(function (each) {
              return (
                <Stack
                  key={each.label}
                  direction="row"
                  spacing={3}
                  onClick={each.func}
                  alignItems="center"
                  justifyItems="flex-start"
                  sx={{
                    px: '15px',
                    py: '10px',
                    cursor: 'pointer',
                    ':hover': {
                      backgroundColor: '#DDDDDD',
                      opacity: 0.8,
                    },
                  }}
                >
                  <Box>
                    <Typography align="left" fontSize={13} fontWeight={600}>
                      {each.label}
                    </Typography>
                  </Box>
                </Stack>
              );
            })}
          </Box>
        </Box>
      </Modal>

      {confirmModalSwitch === true && (
        <ConfirmModal
          title="작업 확인"
          contents={`정말 해당 작업을 실행하시겠습니까?\n(${confirmState} 단계로 이동)`}
          modalSwitch={confirmModalSwitch}
          setModalSwitch={setConfirmModalSwitch}
          func={confirmState === '이전' ? prevStep : nextStep}
        />
      )}

      {workCompletionSwitch && (
        <PaperModalForm modalSwitch={workCompletionSwitch} setModalSwitch={setWorkCompletionSwitch}>
          <WorkCompletion selectedProductionId={selectedProductionId} />
        </PaperModalForm>
      )}

      {changeWorkCompletionSwitch && (
        <PaperModalForm
          modalSwitch={changeWorkCompletionSwitch}
          setModalSwitch={setChangeWorkCompletionSwitch}
        >
          <ChangeWorkCompletion
            selectedProductionId={selectedProductionId}
            setChangeWorkCompletionSwitch={setChangeWorkCompletionSwitch}
            setRefreshSwitch={setRefreshSwitch}
          />
        </PaperModalForm>
      )}
    </div>
  );
}

StatusModal.defaultProps = {
  modalSwitch: true,
  setModalSwitch: () => {},
  selectedData: {},
  setRefreshSwitch: () => {},
};

StatusModal.propTypes = {
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  // eslint-disable-next-line
  selectedData: PropTypes.object,
  setRefreshSwitch: PropTypes.func,
};
