import { Box, Typography } from '@mui/material';
import React from 'react';
import CurrentSummary from './CurrentSummary';
import HistoryGraph from './HistoryGraph';

export default function Dashboard() {
  return (
    <div>
      <Typography align="left" fontSize={30} fontWeight={500} sx={{ mb: '20px' }}>
        Dashboard
      </Typography>

      <CurrentSummary />

      <Box sx={{ height: '45px' }} />

      <HistoryGraph />
    </div>
  );
}
