/* eslint-disable */
import { createContext, useState } from 'react';

const LoginContext = createContext({
  userAuthority: '로그인X',
  loginProcess: () => {},
});

const LoginProvider = ({ children }) => {
  const [userAuthority, setUserAuthority] = useState('로그인X');

  const loginProcess = grade => {
    setUserAuthority(grade);
  };

  return (
    <LoginContext.Provider
      value={{
        userAuthority,
        loginProcess,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };
