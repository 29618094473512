import { Container } from '@mui/material';
import { styled } from '@mui/system';

/** width, px를 포함한 Container  */
export const FixedContainer = styled(Container)(() => ({
  backgroundColor: '#FFF',
  paddingRight: '50px',
  paddingLeft: '50px',
  maxWidth: '1100px !important',
}));

/** width, pt, pb를 포함한 Container  */
export const FixedBodyContainer = styled(Container)(() => ({
  minHeight: '100vh',
  backgroundColor: '#FFF',
  paddingRight: '50px',
  paddingLeft: '50px',
  paddingTop: '50px',
  paddingBottom: '40px',
  maxWidth: '1100px !important',
}));
