import React, { useContext, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Modal, Button, Box, TextField, Typography, Stack } from '@mui/material';
import { url } from '../../../component/commonVariable';
import { NormalContentsSubTitle } from '../../../component/style/TextStyle';
import { sessionExpireError } from '../../../component/commonFunction';
import { LoginContext } from '../../../module/ContextAPI';

export default function DetailInputModal({ modalSwitch, setModalSwitch, selectedRecipe }) {
  const { loginProcess } = useContext(LoginContext);
  const editField = [
    { label: '생산량(g)', keyName: 'totalWeight', width: 0.35 },
    { label: '교반 용기 입력', keyName: 'barrel', width: 0.35 },
    { label: '메모', keyName: 'note', width: 0.7 },
  ];
  const [editInput, setEditInput] = useState({ totalWeight: 0, barrel: '', note: '' });

  // editData
  const onChangeEditInput = e => {
    const { name, value } = e.target;
    setEditInput({
      ...editInput,
      [name]: value,
    });
  };

  const modalClose = () => {
    setModalSwitch(false);
  };

  const saveData = () => {
    const body = { recipeId: selectedRecipe, ...editInput };
    axios
      .post(`${url}/production/instruction`, body)
      .then(() => {
        alert('생산 지시가 정상적으로 완료되었습니다');
        modalClose();
      })
      .catch(err => {
        const isError = sessionExpireError(err);
        if (isError) {
          loginProcess('로그인X');
          return;
        }
        console.log(err);
        alert('레시피 작업을 하는 중 오류가 발생했습니다\n다시 시도해주세요');
      });
  };

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 500,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 40px 35px 40px',
            outline: 'none',
          }}
        >
          <Typography align="center" fontSize={24} fontWeight={600} sx={{ mb: '30px' }}>
            생산 지시하기
          </Typography>

          {editField.map(function (each) {
            return (
              <Stack
                key={each.label}
                direction="row"
                spacing={3}
                alignItems="center"
                justifyItems="flex-start"
                sx={{ mb: '15px' }}
              >
                <Box sx={{ width: '110px' }}>
                  <Typography align="left" fontSize={18} fontWeight={600}>
                    {each.label}
                  </Typography>
                </Box>
                <TextField
                  name={each.keyName}
                  value={editInput[each.keyName] || ''}
                  onChange={onChangeEditInput}
                  sx={{ width: each.width }}
                  type={each.keyName === 'totalWeight' ? 'number' : null}
                />
                {each.addText && <NormalContentsSubTitle>{each.addText}</NormalContentsSubTitle>}
              </Stack>
            );
          })}

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '30px' }}>
            <Button
              variant="contained"
              onClick={saveData}
              sx={{ color: 'white', mr: '30px', width: '100px' }}
            >
              완료
            </Button>

            <Button
              variant="contained"
              color="cancel"
              onClick={modalClose}
              sx={{ width: '100px', color: '#FFF' }}
            >
              닫기
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

DetailInputModal.defaultProps = {
  modalSwitch: true,
  setModalSwitch: () => {},
  selectedRecipe: 0,
};

DetailInputModal.propTypes = {
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  selectedRecipe: PropTypes.number,
};
