import React, { useState } from 'react';
import { Table, TableHead, TableBody, TableRow, Grid, Button } from '@mui/material';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { DocStyledRow, DocStyledCell } from '../style/TableStyle';
import { MainTitle } from '../style/TextStyle';
import { recipeFormHook } from '../../module/useEffectHooks';
import { changeDateDash } from '../commonFunction';

export default function RecipeForm({ recipeId }) {
  const recipeInfo = ['레시피 넘버', '분류', '브랜드/회사', '제품명', '최종 수정일'];

  const [dataList, setDataList] = useState({});
  const [recipeDetailList, setRecipeDetailList] = useState([]);

  const recipeDetailLabel = ['원자재', `${dataList.containerVolume}ml 기준`, '100% 기준'];

  recipeFormHook(recipeId, setDataList, setRecipeDetailList, setRecipeDetailList);

  const captureImg = () => {
    const capDiv = document.getElementById('captureDiv');
    html2canvas(capDiv).then(canvas => {
      const link = document.createElement('a');
      link.download = `레시피_${dataList.category}-${dataList.companyBrand}_${dataList.name}`;
      link.href = canvas.toDataURL();
      document.body.appendChild(link);
      link.click();
    });
  };

  const capturePdf = () => {
    html2canvas(document.getElementById('captureDiv'), { scale: 9 }).then(function (canvas) {
      const filename = `레시피_${dataList.category}-${dataList.companyBrand}_${dataList.name}.pdf`;
      // eslint-disable-next-line
      const doc = new jsPDF('p', 'mm', 'a4');

      const imgData = canvas.toDataURL('image/jpeg');
      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(filename);
    });
  };

  return (
    <div>
      <MainTitle>레시피 출력</MainTitle>

      {/* 레시피 기본 정보 부분 */}
      <Table sx={{ border: '2px solid #333333', mt: '10px' }}>
        <TableHead>
          <TableRow>
            {recipeInfo.map(each => (
              <DocStyledCell key={each}>{each}</DocStyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell width="20%">{dataList.recipeNum}</DocStyledCell>
            <DocStyledCell width="20%">{dataList.category}</DocStyledCell>
            <DocStyledCell width="20%">{dataList.companyBrand}</DocStyledCell>
            <DocStyledCell width="20%">{dataList.name}</DocStyledCell>
            <DocStyledCell width="20%">{changeDateDash(dataList.createdAt)}</DocStyledCell>
          </DocStyledRow>
        </TableBody>
        <TableHead>
          <TableRow>
            <DocStyledCell colSpan={2}>중탕</DocStyledCell>
            <DocStyledCell colSpan={3}>메모</DocStyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DocStyledRow>
            <DocStyledCell colSpan={2}>{dataList.boiling || ' '}</DocStyledCell>
            <DocStyledCell colSpan={3}>{dataList.note || ' '}</DocStyledCell>
          </DocStyledRow>
        </TableBody>
      </Table>
      {/* 레시피 상세 부분 */}
      <Table sx={{ border: '2px solid #333333', mt: '20px' }}>
        <TableHead>
          <TableRow>
            {recipeDetailLabel.map(each => (
              <DocStyledCell key={each}>{each}</DocStyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {recipeDetailList.map(each => (
            <DocStyledRow key={each.name}>
              <DocStyledCell width="33.3%">{each.name}</DocStyledCell>
              <DocStyledCell width="33.3%">{each.containerStandard}g</DocStyledCell>
              <DocStyledCell width="33.3%">{each.percent}%</DocStyledCell>
            </DocStyledRow>
          ))}
          <DocStyledRow>
            <DocStyledCell width="33.3%" sx={{ fontWeight: 700 }}>
              합계
            </DocStyledCell>
            <DocStyledCell width="33.3%" sx={{ fontWeight: 700 }}>
              {dataList.containerVolume}g
            </DocStyledCell>
            <DocStyledCell width="33.3%" sx={{ fontWeight: 700 }}>
              100%
            </DocStyledCell>
          </DocStyledRow>
        </TableBody>
      </Table>

      <Grid container direction="row" justifyContent="flex-end" sx={{ mt: '20px' }}>
        <Button
          color="secondary"
          data-html2canvas-ignore="true"
          onClick={capturePdf}
          sx={{ width: 100, height: 30, mr: '10px' }}
        >
          PDF로 저장
        </Button>
        <Button data-html2canvas-ignore="true" onClick={captureImg} sx={{ width: 100, height: 30 }}>
          PNG로 저장
        </Button>
      </Grid>
    </div>
  );
}

RecipeForm.defaultProps = {
  recipeId: 0,
};

RecipeForm.propTypes = {
  recipeId: PropTypes.number,
};
