import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, Stack, Grid, TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { MainTitle, SubTitle } from '../../component/style/TextStyle';
import DropDown from '../../component/ui/DropDown';
import { materialCategoryList, url } from '../../component/commonVariable';
import { AutoCompleteMaterial } from '../../component/ui/AutoComplete';
import { recipeDetailHook } from '../../module/useEffectHooks';
import { changeDateDot, sessionExpireError } from '../../component/commonFunction';
import ConfirmModal from '../../component/ui/ConfirmModal';
import { LoginContext } from '../../module/ContextAPI';

export default function RecipeAddEditModal({
  modalSwitch,
  setModalSwitch,
  editRecipeId,
  refreshSwitch,
  setRefreshSwitch,
  isEditMode,
}) {
  const { loginProcess } = useContext(LoginContext);
  const recipeInfo = [
    [
      { label: '레시피 번호', keyName: 'recipeNum', width: '90px', maxLength: 100 },
      { label: '분류', keyName: 'category', width: '90px', maxLength: 20 },
      { label: '회사/브랜드', keyName: 'companyBrand', width: '90px', maxLength: 30 },
      { label: '상태', keyName: 'status', width: '90px', maxLength: 90 },
    ],
    [
      { label: '레시피명', keyName: 'name', width: '70px', maxLength: 40 },
      { label: '향 종류', keyName: 'flavorType', width: '70px', maxLength: 20 },
      { label: '향 상세', keyName: 'flavorDetail', width: '70px', maxLength: 20 },
      { label: '쿨링', keyName: 'cooling', width: '70px', maxLength: 5 },
    ],
    [
      { label: '유형', keyName: 'inhaleType', width: '40px', maxLength: 10 },
      { label: '용량', keyName: 'containerVolume', width: '40px', maxLength: 10 },
      { label: '중탕', keyName: 'boiling', width: '40px', maxLength: 45 },
      { label: '비고', keyName: 'note', width: '40px', maxLength: 90 },
    ],
  ];
  // db에 ''를 위한 초기값 (undefined로 등록되거나 아니면 || ''로 일일히 등록해야 하는)
  const [recipeInfoData, setRecipeInfoData] = useState({
    recipeNum: '',
    category: '',
    companyBrand: '',
    status: '',
    name: '',
    flavorType: '',
    flavorDetail: '',
    cooling: '',
    inhaleType: '',
    containerVolume: '',
    boiling: '',
    note: '',
  });

  const defaultValue = [
    { category: '기타', name: 'PG', materialWeight: '', materialId: 208 },
    { category: '기타', name: 'VG', materialWeight: '', materialId: 209 },
    { category: '기타', name: 'Nicotine', materialWeight: '', materialId: 210 },
    // { category: '스위트너', name: '혼합_스위트너', materialWeight: '', materialId: 3 },
    // { category: '쿨링', name: '쿨링4믹스', materialWeight: '', materialId: 4 },
    // { category: '향료', name: '땅콩', materialWeight: '', materialId: 5 },
  ];

  // 레시피 상세 정보
  const [recipeDetailList, setRecipeDetailList] = useState(defaultValue);
  const [recipeDetailOgList, setRecipeDetailOgList] = useState(defaultValue);

  const [deleteModalSwitch, setDeleteModalSwitch] = useState(false);

  recipeDetailHook(editRecipeId, setRecipeInfoData, setRecipeDetailList, setRecipeDetailOgList);

  // 레시피 정보 수정
  const onChangeRecipeInfo = e => {
    const { name, value } = e.target;
    const tempInfoInput = { ...recipeInfoData };
    tempInfoInput[name] = value;
    setRecipeInfoData(tempInfoInput);
  };

  // 레시피 상세 목록 추가
  const addMaterial = () => {
    const tempInput = [...recipeDetailList];
    tempInput.push({ category: '', materialWeight: '' });
    setRecipeDetailList(tempInput);
  };

  // 카테고리 선택
  const onChangeCategory = (e, index) => {
    const tempInput = [...recipeDetailList];
    tempInput[index].category = e;

    // 오작동 방지를 위해 나머지 초기화
    delete tempInput[index].materialId;
    delete tempInput[index].name;
    tempInput[index].materialWeight = '';
    setRecipeDetailList(tempInput);
  };

  // 각각의 재료 투입량
  const onChangeMaterialWeight = (e, index) => {
    const { value } = e.target;
    const tempInput = [...recipeDetailList];
    tempInput[index].materialWeight = value;
    setRecipeDetailList(tempInput);

    // 합산
    let totalWeight = 0;
    for (let i = 0; i < recipeDetailList.length; i += 1) {
      totalWeight += Number(recipeDetailList[i].materialWeight);
    }
    setRecipeInfoData({ ...recipeInfoData, containerVolume: totalWeight });
  };

  // 하나의 재료 항목 삭제
  const deleteMaterial = index => {
    const tempInput = [...recipeDetailList];
    tempInput.splice(index, 1);
    setRecipeDetailList(tempInput);
  };

  // 데이터 저장/수정
  const saveData = () => {
    // 레시피 기본 정보 검사
    if (!recipeInfoData.name) {
      alert('레시피명을 입력해주세요!');
    } else if (!recipeInfoData.containerVolume) {
      alert('용량을 입력해주세요!');
    } else {
      // 레시피 상세 내용 유무 검사
      // eslint-disable-next-line
      if (recipeDetailList.length === 0) {
        alert('레시피 상세 내용을 입력해주세요!');
      } else {
        const category = recipeDetailList.find(each => each.category === '');
        const name = recipeDetailList.find(each => each.name === '' || each.name === null);
        const materialWeight = recipeDetailList.find(each => each.materialWeight === '');

        // 레시피 상세 내용 유효값 검사
        if (category) {
          alert('레시피 상세\n카테고리를 선택하지 않은 행이 있습니다.');
        } else if (name) {
          alert(`레시피 상세\n재료를 선택하지 않은 행이 있습니다.\n\n* ${name.category}`);
        } else if (materialWeight) {
          alert(`레시피 상세\n투입량(g)을 입력하지 않은 행이 있습니다.
          \n* ${materialWeight.name}`);
        } else {
          // 중복 검사 (중복 이름)
          let isDuplicate = 0;
          let duplicateCheckArray;
          for (let i = 0; i < recipeDetailList.length; i += 1) {
            duplicateCheckArray = recipeDetailList.filter(
              each => each.name === recipeDetailList[i].name,
            );
            if (duplicateCheckArray.length !== 1) {
              isDuplicate = 1;
              break;
            }
          }

          if (isDuplicate === 1) {
            alert(`중복된 재료가 있습니다.\n* ${duplicateCheckArray[0].name}`);
          } else {
            // 수정 시, og 데이터와 비교. 수정, 추가, 삭제 선별.
            if (isEditMode) {
              for (let i = 0; i < recipeDetailList.length; i += 1) {
                const actionCheckArray = recipeDetailOgList.filter(
                  each => each.materialId === recipeDetailList[i].materialId,
                );
                // 기존 레시피에는 없는, 새로운 재료
                if (actionCheckArray.length === 0) {
                  recipeDetailList[i].action = 'INSERT';
                  // 같은 재료가 있다 => 업데이트 대상
                } else {
                  recipeDetailList[i].action = 'UPDATE';
                }
              }
            }
            const body = [recipeInfoData, ...recipeDetailList];

            // 삭제할 행 추가
            if (isEditMode) {
              for (let i = 0; i < recipeDetailOgList.length; i += 1) {
                const actionCheckArray = recipeDetailList.filter(
                  each => each.materialId === recipeDetailOgList[i].materialId,
                );
                if (actionCheckArray.length === 0) {
                  const deleteRow = { ...recipeDetailOgList[i], action: 'DELETE' };
                  body.push(deleteRow);
                }
              }
            }

            // console.log('하이', body);

            const mode = isEditMode ? 'edit' : 'add';
            axios
              .post(`${url}/recipe/${mode}`, body)
              .then(() => {
                alert('레시피 작업이 성공적으로 완료되었습니다');
                setRefreshSwitch(!refreshSwitch);
                modalClose();
              })
              .catch(err => {
                const isError = sessionExpireError(err);
                if (isError) {
                  loginProcess('로그인X');
                  return;
                }
                console.log(err);
                alert('레시피 작업을 하는 중 오류가 발생했습니다\n다시 시도해주세요');
              });
          }
        }
      }
    }
  };

  const deleteRecipe = () => {
    axios.post(`${url}/recipe/delete/${recipeInfoData.recipeId}`).then(() => {
      alert('레시피 삭제가 성공적으로 완료되었습니다');
      setRefreshSwitch(!refreshSwitch);
      modalClose();
    });
  };

  // 삭제 모달
  const openDeleteModal = () => {
    setDeleteModalSwitch(true);
  };

  // 모달 닫기
  const modalClose = () => {
    setModalSwitch(false);
    setRefreshSwitch(!refreshSwitch);
  };

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 990,
            height: 550,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 60px 50px 60px',
            outline: 'none',
            overflowY: 'scroll',
          }}
        >
          <Typography align="center" fontSize={28} fontWeight={600}>
            레시피 {isEditMode ? '수정' : '추가'}
          </Typography>

          <Box sx={{ height: '1px', backgroundColor: '#C9C9C9', my: '20px' }} />

          {/* 레시피 기본 정보 부분 */}
          <Stack direction="row" spacing="10px">
            <MainTitle sx={{ fontSize: '22px' }}>레시피 정보</MainTitle>
            {isEditMode && (
              <SubTitle sx={{ pt: '5px' }}>{`최종 수정 : ${changeDateDot(
                recipeInfoData.updatedAt,
              )} / ${recipeInfoData.lastEditor}`}</SubTitle>
            )}
          </Stack>

          <Grid container direction="row" justifyContent="center" spacing="20px">
            {recipeInfo.map(eachColumn => (
              <Grid item xs={4} key={`${eachColumn[0].label}_grid`}>
                {eachColumn.map(each => (
                  <Stack
                    direction="row"
                    spacing="10px"
                    key={each.label}
                    sx={{ mb: '5px' }}
                    alignItems="center"
                  >
                    <Box sx={{ width: each.width }}>{each.label}</Box>
                    <TextField
                      name={each.keyName}
                      type={each.label === '용량' ? 'number' : ''}
                      value={recipeInfoData[each.keyName] || ''}
                      onChange={e => onChangeRecipeInfo(e)}
                      inputProps={{
                        maxLength: each.maxLength,
                        style: { padding: '7px 0px 7px 13px' },
                      }}
                    />
                  </Stack>
                ))}
              </Grid>
            ))}
          </Grid>

          {/* 레시피 상세 입력 부분 */}
          <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: '40px' }}>
            <MainTitle sx={{ fontSize: '22px' }}>레시피 상세</MainTitle>

            <Button onClick={addMaterial} sx={{ width: '90px' }}>
              재료추가
            </Button>
          </Grid>

          <Grid container direction="row" justifyContent="flex-start" spacing="10px">
            {recipeDetailList.map((each, index) => (
              // eslint-disable-next-line
              <Grid item xs={6} key={`레시피 상세 입력 ${index}`}>
                <Stack direction="row" alignItems="center" spacing="8px">
                  <DropDown
                    selectList={materialCategoryList}
                    value={each.category}
                    onChange={e => onChangeCategory(e, index)}
                    width="110px"
                  />
                  {recipeDetailList[index].category !== '' && (
                    <AutoCompleteMaterial
                      recipeDetailList={recipeDetailList}
                      setRecipeDetailList={setRecipeDetailList}
                      index={index}
                      category={recipeDetailList[index].category}
                      minWidth="160px"
                    />
                  )}
                  {recipeDetailList[index].category !== '' && (
                    <TextField
                      value={recipeDetailList[index].materialWeight || ''}
                      onChange={e => onChangeMaterialWeight(e, index)}
                      type="number"
                      sx={{ width: '100px' }}
                    />
                  )}
                  {recipeDetailList[index].category !== '' && (
                    <Button onClick={() => deleteMaterial(index)} variant="outlined">
                      삭제
                    </Button>
                  )}
                </Stack>
              </Grid>
            ))}
          </Grid>

          {/* 최하단 확인 / 취소 */}
          <Grid container justifyContent="center" sx={{ mt: '40px' }}>
            <Button onClick={saveData} sx={{ width: '100px', height: '30px' }}>
              {isEditMode ? '수정하기' : '추가하기'}
            </Button>
            <Button
              onClick={modalClose}
              color="cancel"
              sx={{ width: '100px', height: '30px', color: '#FFF', ml: '10px' }}
            >
              취소
            </Button>

            {isEditMode && (
              <Button
                onClick={openDeleteModal}
                color="red"
                sx={{ width: '100px', height: '30px', color: '#FFF', ml: '10px' }}
              >
                삭제
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>

      {deleteModalSwitch && (
        <ConfirmModal
          modalSwitch={deleteModalSwitch}
          setModalSwitch={setDeleteModalSwitch}
          title="정말 삭제하시겠습니까?"
          contents={`레시피의 모든 정보가 삭제됩니다\n\n* 이미 한 번 생산된 레시피의 경우,\n삭제 대신 단종을 이용해주세요`}
          func={deleteRecipe}
        />
      )}
    </div>
  );
}

RecipeAddEditModal.defaultProps = {
  isEditMode: false,
  modalSwitch: true,
  setModalSwitch: () => {},
  editRecipeId: 0,
  refreshSwitch: true,
  setRefreshSwitch: () => {},
};

RecipeAddEditModal.propTypes = {
  isEditMode: PropTypes.bool,
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  editRecipeId: PropTypes.number,
  refreshSwitch: PropTypes.bool,
  setRefreshSwitch: PropTypes.func,
};
