import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Card, Typography, Grid, TextField, Button } from '@mui/material';
import { LoginContext } from '../../module/ContextAPI';
import { url } from '../../component/commonVariable';

axios.defaults.withCredentials = true;

export default function Entrance() {
  const [inputId, setInputId] = useState('');
  const [inputPassword, setInputPassword] = useState('');

  const { loginProcess } = useContext(LoginContext);

  useEffect(() => {
    axios.get(`${url}/user/isLogin`).then(res => {
      if (res.data !== '로그인X') {
        loginProcess(res.data.authority);
      }
    });
  }, []);

  const onChangeId = e => {
    setInputId(e.target.value);
  };

  const onChangePassword = e => {
    setInputPassword(e.target.value);
  };

  // 엔터 로그인
  const onCheckEnter = e => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const login = () => {
    const body = { id: inputId, password: inputPassword };
    axios
      .post(`${url}/user/loginProcess`, body)
      .then(res => {
        // console.log(res.data);
        loginProcess(res.data.authority);
      })
      .catch(err => {
        if (err.response.data === 'id') {
          alert('없는 아이디입니다');
        } else if (err.response.data === 'psw') {
          alert('비밀번호가 잘못되었습니다');
        }
      });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh', backgroundColor: '#24408E' }}
    >
      <Typography variant="h3" color="#FFF" sx={{ mt: '-55px' }}>
        ConnectedKorea Admin
      </Typography>

      <Grid container justifyContent="center" sx={{ pt: '40px' }}>
        <Card sx={{ width: '450px', py: '30px', px: '40px', opacity: 0.8 }}>
          <form>
            <Typography variant="h5" fontWeight={600} sx={{ mb: '20px' }}>
              로그인
            </Typography>
            <Typography align="left">아이디</Typography>
            <TextField
              fullWidth
              value={inputId}
              onChange={onChangeId}
              onKeyPress={onCheckEnter}
              sx={{ mb: '20px' }}
            />
            <Typography align="left">비밀번호</Typography>
            <TextField
              fullWidth
              value={inputPassword}
              onChange={onChangePassword}
              onKeyPress={onCheckEnter}
              type="password"
              autoComplete="off"
            />
            <Button
              variant="contained"
              onClick={login}
              sx={{ color: 'white', mt: '30px', width: '150px' }}
            >
              로그인
            </Button>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
}
