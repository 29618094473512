import React from 'react';
import ReactPagination from 'react-js-pagination';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@mui/material';
import './Pagenation.css';

export default function Pagination({ page, totalItem, setPage, itemNumber }) {
  return (
    <Box sx={{ mt: '35px' }}>
      <ReactPagination
        activePage={page}
        itemsCountPerPage={itemNumber}
        totalItemsCount={totalItem}
        pageRangeDisplayed={10}
        prevPageText="‹"
        firstPageText="‹‹"
        nextPageText="›"
        lastPageText="››"
        onChange={setPage}
      />
      <Grid container justifyContent="center" sx={{ mt: '10px' }}>
        <Typography>
          <b>전체 {totalItem}</b> ({page} / {Math.ceil(totalItem / itemNumber)})
        </Typography>
      </Grid>
    </Box>
  );
}

Pagination.defaultProps = {
  page: 1,
  totalItem: 100,
  itemNumber: 12,
  setPage: () => {},
};

Pagination.propTypes = {
  page: PropTypes.number,
  totalItem: PropTypes.number,
  itemNumber: PropTypes.number,
  setPage: PropTypes.func,
};
