import React, { useState } from 'react';
import { Button, Grid, Stack, TextField, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { MainTitle } from '../style/TextStyle';
import DropDown from './DropDown';
import { AutoCompleteCategoryFilter } from './AutoComplete';

export default function RecipeFilter({
  categoryFilter,
  setCategoryFilter,
  searchFilter,
  setSearchFilter,
  categoryFilterSearch,
  searchFilterSearch,
}) {
  const [autoCompleteResetSwitch, setAutoCompleteResetSwitch] = useState(true);

  // 분류 필터
  const categoryList = [
    { label: '분류', keyName: 'category' },
    { label: '회사/브랜드', keyName: 'companyBrand' },
    { label: '향 종류', keyName: 'flavorType' },
    { label: '향 상세', keyName: 'flavorDetail' },
  ];

  // 검색 필터
  const dataList = ['레시피 번호', '레시피 이름'];

  const onChangeSearchFilter = e => {
    const tempSearchInput = { ...searchFilter };
    tempSearchInput.searchFilter = e;
    setSearchFilter(tempSearchInput);
  };

  const onChangeSearchInput = e => {
    const tempSearchInput = { ...searchFilter };
    tempSearchInput.searchInput = e.target.value;
    setSearchFilter(tempSearchInput);
  };

  // 여기서 리셋. 자동완성 값 안바뀌는 것은 autoCompleteResetSwitch 이용
  const resetCategoryFilter = () => {
    setAutoCompleteResetSwitch(!autoCompleteResetSwitch);
    const tempObj = {
      category: '',
      companyBrand: '',
      flavorType: '',
      flavorDetail: '',
    };
    setCategoryFilter(tempObj);
    categoryFilterSearch();
  };

  return (
    <Grid container direction="row" justifyContent="flex-start" spacing="10px" sx={{ mb: '40px' }}>
      <Grid item xs={4.4}>
        <MainTitle sx={{ fontWeight: 700 }}>검색 필터</MainTitle>

        <Stack direction="row" alignItems="flex-start" spacing="0px">
          <Box>
            {categoryList.map(each => (
              <Stack key={each.label} direction="row" alignItems="center" sx={{ mb: '5px' }}>
                <Box sx={{ width: '95px', textAlign: 'left', fontWeight: 500 }}>{each.label}</Box>
                <AutoCompleteCategoryFilter
                  categoryFilter={categoryFilter}
                  setCategoryFilter={setCategoryFilter}
                  categoryKeyName={each.keyName}
                  autoCompleteResetSwitch={autoCompleteResetSwitch}
                  minWidth="150px"
                />
              </Stack>
            ))}
          </Box>
          <Grid alignItems="flex-start">
            <Button onClick={categoryFilterSearch} sx={{ width: 0.7, height: '84px', mb: '8px' }}>
              검색
            </Button>
            <Button
              color="cancel"
              onClick={resetCategoryFilter}
              sx={{ width: 0.7, height: '84px', color: '#FFF' }}
            >
              초기화
            </Button>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={5}>
        <MainTitle sx={{ fontWeight: 700 }}>검색</MainTitle>
        <Stack direction="row" spacing="5px">
          <DropDown
            selectList={dataList}
            value={searchFilter.searchFilter}
            onChange={e => onChangeSearchFilter(e)}
            width="130px"
          />
          <TextField value={searchFilter.searchInput} onChange={e => onChangeSearchInput(e)} />
          <Button onClick={searchFilterSearch}>검색</Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

RecipeFilter.defaultProps = {
  categoryFilter: {},
  setCategoryFilter: () => {},
  searchFilter: {},
  setSearchFilter: () => {},
  categoryFilterSearch: () => {},
  searchFilterSearch: () => {},
};

RecipeFilter.propTypes = {
  categoryFilter: PropTypes.objectOf(PropTypes.string),
  setCategoryFilter: PropTypes.func,
  searchFilter: PropTypes.objectOf(PropTypes.string),
  setSearchFilter: PropTypes.func,
  categoryFilterSearch: PropTypes.func,
  searchFilterSearch: PropTypes.func,
};
