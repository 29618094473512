import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Stack, TextField } from '@mui/material';
import DropDown from './DropDown';
import { AutoCompleteAdditive } from './AutoComplete';

export function SearchFilter({ selectList, searchFilter, setSearchFilter, searchFilterSearch }) {
  const onChangeSearchFilter = e => {
    const tempSearchInput = { ...searchFilter };
    tempSearchInput.searchFilter = e;
    setSearchFilter(tempSearchInput);
  };

  const onChangeSearchInput = e => {
    const tempSearchInput = { ...searchFilter };
    tempSearchInput.searchInput = e.target.value;
    setSearchFilter(tempSearchInput);
  };

  return (
    <Grid container direction="row" justifyContent="flex-start" sx={{ mb: '30px' }}>
      <Stack direction="row" spacing="5px">
        <DropDown
          selectList={selectList}
          value={searchFilter.searchFilter}
          onChange={e => onChangeSearchFilter(e)}
          width="130px"
        />
        <TextField value={searchFilter.searchInput} onChange={e => onChangeSearchInput(e)} />
        <Button onClick={searchFilterSearch}>검색</Button>
      </Stack>
    </Grid>
  );
}

SearchFilter.defaultProps = {
  selectList: [],
  searchFilter: {},
  setSearchFilter: () => {},
  searchFilterSearch: () => {},
};

SearchFilter.propTypes = {
  selectList: PropTypes.arrayOf(PropTypes.string),
  searchFilter: PropTypes.objectOf(PropTypes.string),
  setSearchFilter: PropTypes.func,
  searchFilterSearch: PropTypes.func,
};

export function SearchFilterAdditive({
  selectList,
  searchFilter,
  setSearchFilter,
  searchFilterSearch,
}) {
  const onChangeSearchFilter = e => {
    const tempSearchInput = { ...searchFilter };
    tempSearchInput.searchFilter = e;
    setSearchFilter(tempSearchInput);
  };

  const onChangeSearchInput = e => {
    const tempSearchInput = { ...searchFilter };
    if (searchFilter.searchFilter === '분류' || searchFilter.searchFilter === '제조사') {
      tempSearchInput.searchInput = e;
    } else {
      tempSearchInput.searchInput = e.target.value;
    }
    setSearchFilter(tempSearchInput);
  };

  return (
    <Grid container direction="row" justifyContent="flex-start" sx={{ mb: '30px' }}>
      <Stack direction="row" spacing="5px">
        <DropDown
          selectList={selectList}
          value={searchFilter.searchFilter}
          onChange={e => onChangeSearchFilter(e)}
          width="130px"
        />
        {searchFilter.searchFilter === '분류' || searchFilter.searchFilter === '제조사' ? (
          <AutoCompleteAdditive
            onChangeSearchValue={e => onChangeSearchInput(e)}
            categoryKeyName={searchFilter.searchFilter === '분류' ? 'category' : 'company'}
          />
        ) : (
          <TextField value={searchFilter.searchInput} onChange={e => onChangeSearchInput(e)} />
        )}
        <Button onClick={searchFilterSearch}>검색</Button>
      </Stack>
    </Grid>
  );
}

SearchFilterAdditive.defaultProps = {
  selectList: [],
  searchFilter: {},
  setSearchFilter: () => {},
  searchFilterSearch: () => {},
};

SearchFilterAdditive.propTypes = {
  selectList: PropTypes.arrayOf(PropTypes.string),
  searchFilter: PropTypes.objectOf(PropTypes.string),
  setSearchFilter: PropTypes.func,
  searchFilterSearch: PropTypes.func,
};
