import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography, CardMedia } from '@mui/material';
import { FixedContainer } from '../style/ContainerStyle';
import { url } from '../commonVariable';
import { LoginContext } from '../../module/ContextAPI';
import PasswordChangeModal from './PasswordChangeModal';

axios.defaults.withCredentials = true;

function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { loginProcess, userAuthority } = useContext(LoginContext);

  const firstDepthPathName = `/${location.pathname.split('/')[1]}`;

  // A, B등급의 메뉴 리스트
  const menuListAB = [
    { label: '대시보드', link: '/', firstDepth: '/' },
    { label: '생산', link: '/Production/Instruction', firstDepth: '/Production' },
    { label: '레시피', link: '/Recipe', firstDepth: '/Recipe' },
    { label: '원자재', link: '/Material/Additive', firstDepth: '/Material' },
  ];

  // C, D등급의 메뉴 리스트
  const menuListCD = [
    { label: '대시보드', link: '/', firstDepth: '/' },
    { label: '생산', link: '/Production/Instruction', firstDepth: '/Production' },
  ];

  const menuList = userAuthority === 'A' || userAuthority === 'B' ? menuListAB : menuListCD;

  const loginMenuList =
    userAuthority === 'A'
      ? ['계정관리', '로그아웃', '비밀번호 변경']
      : ['로그아웃', '비밀번호 변경'];

  const [passwordChangeModal, setPasswordChangeModal] = useState(false);

  const goToLink = link => {
    if (link === '/Recipe' || link === '/Material/Additive') {
      if (userAuthority === 'A' || userAuthority === 'B') {
        navigate(link);
      } else {
        alert('접근 권한이 없습니다!');
      }
    } else {
      navigate(link);
    }
  };

  const loginMenuAction = label => {
    if (label === '로그아웃') {
      logOut();
    } else if (label === '계정관리') {
      navigate('/User');
    } else if (label === '비밀번호 변경') {
      setPasswordChangeModal(true);
    }
  };

  const logOut = () => {
    axios.post(`${url}/user/logoutProcess`).then(() => loginProcess('로그인X'));
  };

  return (
    <div>
      <nav>
        <AppBar elevation={0} sx={{ backgroundColor: '#FFF', borderBottom: '0.5px solid #C9C9C9' }}>
          <FixedContainer>
            <Toolbar disableGutters variant="dense" sx={{ height: '55px' }}>
              {/* 좌측 로고 영역 */}
              <CardMedia
                onClick={() => {
                  goToLink('/');
                }}
                component="img"
                image="/logo192.png"
                alt="logo"
                sx={{
                  width: '40px',
                  cursor: 'pointer',
                  display: 'flex',
                  mr: '30px',
                }}
              />

              {/* 메뉴 리스트 */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {menuList.map(each => (
                  <Typography
                    key={each.label}
                    onClick={() => {
                      goToLink(each.link);
                    }}
                    fontWeight={firstDepthPathName === each.firstDepth ? 700 : 500}
                    sx={{
                      mr: '20px',
                      cursor: 'pointer',
                      fontSize: '18px',
                    }}
                  >
                    {each.label}
                  </Typography>
                ))}
              </Box>

              {/* 맨 우측 텍스트 */}
              {loginMenuList.map(each => (
                <Typography
                  key={each}
                  onClick={() => {
                    loginMenuAction(each);
                  }}
                  sx={{
                    ml: '17px',
                    cursor: 'pointer',
                    fontSize: '15px',
                  }}
                >
                  {each}
                </Typography>
              ))}
            </Toolbar>
          </FixedContainer>
        </AppBar>
      </nav>

      {passwordChangeModal && (
        <PasswordChangeModal
          modalSwitch={passwordChangeModal}
          setModalSwitch={setPasswordChangeModal}
        />
      )}
    </div>
  );
}

export default NavBar;
