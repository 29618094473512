import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { MainTitle } from '../../component/style/TextStyle';
import { dashboardGraph } from '../../module/useEffectHooks';

export default function HistoryGraph() {
  const [dataList, setDataList] = useState([]);
  // 최근 1주, 그 전 1주 등 조회 가능하도록
  const [period, setPeriod] = useState(0);

  const changePagination = mode => {
    if (mode === 'up') {
      setPeriod(period + 1);
    } else if (mode === 'down' && period !== 0) {
      setPeriod(period - 1);
    }
  };

  dashboardGraph(setDataList, period);

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between">
        <MainTitle>생산 히스토리</MainTitle>
        <Box>
          <IconButton onClick={() => changePagination('up')}>
            <ArrowBackIosRoundedIcon />
          </IconButton>
          <IconButton onClick={() => changePagination('down')}>
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </Box>
      </Grid>

      <BarChart
        width={1000}
        height={400}
        data={dataList}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="ODM" stackId="a" fill="#8884d8" />
        <Bar dataKey="OEM" stackId="a" fill="#82ca9d" />
        <Bar dataKey="브랜드" stackId="a" fill="#10263B" />
      </BarChart>

      <Box sx={{ height: '50px' }} />
    </div>
  );
}
