import React, { useState, useContext } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Button,
  Grid,
  TableContainer,
  Paper,
  Stack,
} from '@mui/material';
import RecipeFilter from '../../component/ui/RecipeFilter';
import { StyledRow, StyledCell } from '../../component/style/TableStyle';
import { MainTitle } from '../../component/style/TextStyle';
import Pagination from '../../component/Pagination';
import RecipeAddEditModal from './RecipeAddEditModal';
import { recipeSearchHook } from '../../module/useEffectHooks';
import { recipeNumber } from '../../component/commonVariable';
import { changeDateDot, downloadAll } from '../../component/commonFunction';
import RecipeForm from '../../component/ui/RecipeForm';
import COAForm from '../../component/ui/COAForm';
import PaperModalForm from '../../component/ui/PaperModalForm';
import { pageAuthorityCheck } from '../../module/LoginCheck';
import { LoginContext } from '../../module/ContextAPI';

export default function Recipe() {
  pageAuthorityCheck();
  const { userAuthority } = useContext(LoginContext);

  // 검색 필터
  const [categoryFilter, setCategoryFilter] = useState({
    category: '',
    companyBrand: '',
    flavorType: '',
    flavorDetail: '',
  });
  const [searchFilter, setSearchFilter] = useState({
    searchFilter: '레시피 번호',
    searchInput: '',
  });

  // 레시피 데이터 및 페이지
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  const [refreshSwitch, setRefreshSwitch] = useState(true);

  // 폼 출력 스위치
  const [recipeFormSwitch, setRecipeFormSwitch] = useState(false);
  const [coaFormSwitch, setCoaFormSwitch] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const dataTable = [
    '레시피 번호',
    '분류',
    '회사/브랜드',
    '레시피명',
    '유형',
    '향 종류',
    '향 상세',
    '쿨링',
    '용량',
    '중탕',
    '상태',
    '최종 수정',
    '비고',
    '레시피 출력',
    'COA 출력',
    '수정하기',
  ];

  const [editRecipeId, setEditRecipeId] = useState(0);

  recipeSearchHook(categoryFilter, searchFilter, setDataList, page, setTotalItem, refreshSwitch);

  const [addEditModalSwitch, setAddEditModalSwitch] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const categoryFilterSearch = () => {
    setRefreshSwitch(!refreshSwitch);
  };

  const searchFilterSearch = () => {
    setRefreshSwitch(!refreshSwitch);
  };

  const openRecipeForm = id => {
    if (userAuthority === 'A') {
      setEditRecipeId(id);
      setRecipeFormSwitch(true);
    } else {
      alert('접근 권한이 없습니다!');
    }
  };

  const openCoaForm = id => {
    if (userAuthority === 'A') {
      setEditRecipeId(id);
      setCoaFormSwitch(true);
    } else {
      alert('접근 권한이 없습니다!');
    }
  };

  const openAddEditModal = (mode, id) => {
    if (mode === 'add') {
      setIsEditMode(false);
      setEditRecipeId(0);
      setAddEditModalSwitch(true);
    } else if (mode === 'edit') {
      if (userAuthority === 'A') {
        setEditRecipeId(id);
        setIsEditMode(true);
        setAddEditModalSwitch(true);
      } else {
        alert('접근 권한이 없습니다!');
      }
    }
  };

  // 데이터 다운로드
  const download = async () => {
    if (userAuthority === 'A') {
      try {
        if (isLoading) return;
        await downloadAll('/recipe/download', '레시피 목록', setIsLoading);
      } catch (error) {
        alert(`다운로드에 실패했습니다\n${error}`);
      }
    } else {
      alert('접근 권한이 없습니다!');
    }
  };

  return (
    <div>
      <RecipeFilter
        categoryFilter={categoryFilter}
        setCategoryFilter={setCategoryFilter}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        categoryFilterSearch={categoryFilterSearch}
        searchFilterSearch={searchFilterSearch}
      />

      <Grid container justifyContent="space-between" alignContent="center">
        <MainTitle>레시피 리스트</MainTitle>

        <Stack direction="row" spacing={1}>
          <Button variant="outlined" onClick={download} sx={{ width: '80px', height: '30px' }}>
            {isLoading ? '다운로드중' : 'Export'}
          </Button>
          <Button onClick={() => openAddEditModal('add')} sx={{ width: '90px', height: '30px' }}>
            레시피 추가
          </Button>
        </Stack>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ maxHeight: '480px', mt: '5px', width: '100%', overflowX: 'auto' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {dataTable.map(each => (
                <StyledCell key={each}>{each}</StyledCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {dataList.map(each => (
              <StyledRow
                key={each.recipeId}
                // eslint-disable-next-line
                sx={[
                  each.status === '단종' && {
                    backgroundColor: 'rgba(100, 100, 100, 0.17) !important',
                  },
                ]}
              >
                <StyledCell sx={{ minWidth: '120px' }}>{each.recipeNum}</StyledCell>
                <StyledCell sx={{ minWidth: '40px' }}>{each.category}</StyledCell>
                <StyledCell sx={{ minWidth: '80px' }}>{each.companyBrand}</StyledCell>
                <StyledCell sx={{ minWidth: '80px' }}>{each.name}</StyledCell>
                <StyledCell sx={{ minWidth: '30px' }}>{each.inhaleType}</StyledCell>
                <StyledCell sx={{ minWidth: '40px' }}>{each.flavorType}</StyledCell>
                <StyledCell sx={{ minWidth: '70px' }}>{each.flavorDetail}</StyledCell>
                <StyledCell sx={{ minWidth: '30px' }}>{each.cooling}</StyledCell>
                <StyledCell sx={{ minWidth: '50px' }}>{each.containerVolume}ml</StyledCell>
                <StyledCell sx={{ minWidth: '80px' }}>{each.boiling}</StyledCell>
                {/* eslint-disable-next-line */}
                <StyledCell
                  sx={[
                    each.status === '단종' && { fontWeight: 700, color: '#C21807' },
                    { minWidth: '50px' },
                  ]}
                >
                  {each.status}
                </StyledCell>
                <StyledCell sx={{ minWidth: '120px' }}>
                  {`${each.lastEditor} / ${changeDateDot(each.updatedAt)}`}
                </StyledCell>
                <StyledCell sx={{ minWidth: '130px' }}>{each.note}</StyledCell>
                <StyledCell>
                  <Button
                    onClick={() => openRecipeForm(each.recipeId)}
                    variant="outlined"
                    sx={{ minWidth: '60px' }}
                  >
                    선택
                  </Button>
                </StyledCell>
                <StyledCell>
                  <Button
                    onClick={() => openCoaForm(each.recipeId)}
                    variant="outlined"
                    sx={{ minWidth: '60px' }}
                  >
                    선택
                  </Button>
                </StyledCell>
                <StyledCell>
                  <Button
                    onClick={() => openAddEditModal('edit', each.recipeId)}
                    variant="outlined"
                    sx={{ minWidth: '60px' }}
                  >
                    수정
                  </Button>
                </StyledCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination page={page} setPage={setPage} totalItem={totalItem} itemNumber={recipeNumber} />

      {recipeFormSwitch && (
        <PaperModalForm modalSwitch={recipeFormSwitch} setModalSwitch={setRecipeFormSwitch}>
          <RecipeForm recipeId={editRecipeId} />
        </PaperModalForm>
      )}

      {coaFormSwitch && (
        <PaperModalForm modalSwitch={coaFormSwitch} setModalSwitch={setCoaFormSwitch}>
          <COAForm recipeId={editRecipeId} />
        </PaperModalForm>
      )}

      {addEditModalSwitch && (
        <RecipeAddEditModal
          modalSwitch={addEditModalSwitch}
          setModalSwitch={setAddEditModalSwitch}
          refreshSwitch={refreshSwitch}
          setRefreshSwitch={setRefreshSwitch}
          isEditMode={isEditMode}
          editRecipeId={editRecipeId}
        />
      )}
    </div>
  );
}
