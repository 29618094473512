import React from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

export default function PaperModalForm({ modalSwitch, setModalSwitch, children }) {
  const modalClose = () => setModalSwitch(false);

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 650,
            maxHeight: 600,
            overflowY: 'scroll',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            outline: 'none',
          }}
        >
          <Box id="captureDiv" sx={{ p: '60px 50px 60px 50px' }}>
            {children}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

PaperModalForm.defaultProps = {
  modalSwitch: true,
  setModalSwitch: () => {},
};

PaperModalForm.propTypes = {
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  children: PropTypes.node.isRequired,
};
