import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import PropTypes from 'prop-types';
import { inventoryHistoryNumber } from '../../../component/commonVariable';
import Pagination from '../../../component/Pagination';
import { StyledRow, StyledCell } from '../../../component/style/TableStyle';
import { inventoryHistoryHook } from '../../../module/useEffectHooks';
import { changeDateDetail } from '../../../component/commonFunction';

export default function InventoryHistory({ modalSwitch, setModalSwitch, materialId }) {
  const dataTable = ['일자', '증감', '항목', '변경자'];

  const [inventoryDataList, setInventoryDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  inventoryHistoryHook(materialId, setInventoryDataList, page, setTotalItem);

  // 모달 닫기
  const modalClose = () => {
    setModalSwitch(false);
  };

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 450,
            maxHeight: 550,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 60px 50px 60px',
            outline: 'none',
            overflowY: 'scroll',
          }}
        >
          <Typography align="center" fontSize={28} fontWeight={600}>
            재고 추이 - {inventoryDataList[0] && inventoryDataList[0].name}
          </Typography>

          <Box sx={{ height: '1px', backgroundColor: '#C9C9C9', mt: '15px', mb: '20px' }} />

          <TableContainer component={Paper} sx={{ maxHeight: '480px', mt: '5px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {dataTable.map(each => (
                    <StyledCell key={each}>{each}</StyledCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {inventoryDataList.map(each => (
                  <StyledRow key={each.materialUsageId}>
                    <StyledCell>{changeDateDetail(each.createdAt)}</StyledCell>
                    <StyledCell sx={{ fontWeight: 600, py: '10px !important' }}>
                      {each.changeAmount}
                    </StyledCell>
                    <StyledCell>{each.usageType}</StyledCell>
                    <StyledCell>{each.createdBy}</StyledCell>
                  </StyledRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* 최하단 취소 */}
          <Grid container justifyContent="center">
            <Pagination
              page={page}
              setPage={setPage}
              totalItem={totalItem}
              itemNumber={inventoryHistoryNumber}
            />
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: '40px' }}>
            <Button
              onClick={modalClose}
              color="cancel"
              sx={{ width: '100px', height: '30px', color: '#FFF', ml: '10px' }}
            >
              닫기
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

InventoryHistory.defaultProps = {
  modalSwitch: true,
  setModalSwitch: () => {},
  materialId: 0,
};

InventoryHistory.propTypes = {
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  materialId: PropTypes.number,
};
