import React, { useState, useContext } from 'react';
import axios from 'axios';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Button,
  TableContainer,
  Paper,
} from '@mui/material';
import { MainTitle } from '../../../component/style/TextStyle';
import { SearchFilter } from '../../../component/ui/SearchFilter';
import { StyledRow, StyledCell } from '../../../component/style/TableStyle';
import Pagination from '../../../component/Pagination';
import { historyNumber, url } from '../../../component/commonVariable';
import { changeDateDash } from '../../../component/commonFunction';
import { historySearchHook } from '../../../module/useEffectHooks';
import WorkCompletion from '../../../component/ui/WorkCompletion';
import PaperModalForm from '../../../component/ui/PaperModalForm';
import ConfirmModal from '../../../component/ui/ConfirmModal';
import { LoginContext } from '../../../module/ContextAPI';

export default function History() {
  const { userAuthority } = useContext(LoginContext);

  const searchField = ['작업 넘버', '레시피 넘버', '레시피명'];
  const [searchFilter, setSearchFilter] = useState({
    searchFilter: '작업 넘버',
    searchInput: '',
  });

  const dataTable = [
    '지시 날짜',
    '작업 넘버',
    '현재 상태',
    '레시피 넘버',
    '회사/브랜드',
    '(유형)레시피명',
    '생산무게',
    '작업완료 날짜',
    '작업 시간',
    '작업 삭제',
    '작업내역',
  ];

  // 첨가물 데이터 및 페이지
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  const [refreshSwitch, setRefreshSwitch] = useState(true);

  // 작업내역서
  const [workCompletionSwitch, setWorkCompletionSwitch] = useState(false);
  const [selectedProductionId, setSelectedProductionId] = useState(0);

  // 삭제 확인
  const [deleteConfirmSwitch, setDeleteConfirmSwitch] = useState(false);
  const [selectedData, setSelected] = useState({});

  historySearchHook(searchFilter, setDataList, page, setTotalItem, refreshSwitch);

  const searchFilterSearch = () => {
    setRefreshSwitch(!refreshSwitch);
  };

  const openWorkCompletion = id => {
    setSelectedProductionId(id);
    setWorkCompletionSwitch(true);
  };

  const openDeleteModal = data => {
    if (data.currentState === '대기') {
      if (userAuthority === 'D') {
        alert('권한이 없습니다');
      } else {
        setDeleteConfirmSwitch(true);
        setSelected(data);
      }
    } else {
      alert('대기 상태의 작업만 삭제하실 수 있습니다');
    }
  };

  const deleteProduction = () => {
    const body = {
      currentState: selectedData.currentState,
      productionId: selectedData.productionId,
      recipeId: selectedData.recipeId,
      totalWeight: selectedData.totalWeight,
    };
    axios.post(`${url}/production/status/prev`, body).then(() => {
      alert('작업이 성공적으로 완료되었습니다');
      setRefreshSwitch(!refreshSwitch);
      setDeleteConfirmSwitch(false);
    });
  };

  // 작업자 이름 추가 요청에 따른 추가
  const workerName = state => {
    let keyName;
    if (state === '대기') {
      keyName = 'instructionBy';
    } else if (state === '배합') {
      keyName = 'combinationBy';
    } else if (state === '교반') {
      keyName = 'stirringBy';
    } else if (state === '중탕') {
      keyName = 'boilingBy';
    } else if (state === '교반 종료') {
      keyName = 'endBy';
    } else if (state === '생산팀 수령') {
      keyName = 'relaseBy';
    } else if (state === '작업완료') {
      keyName = 'pickupBy';
    }

    return keyName;
  };

  return (
    <div>
      <SearchFilter
        selectList={searchField}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        searchFilterSearch={searchFilterSearch}
      />

      <MainTitle>생산 리스트</MainTitle>

      <TableContainer component={Paper} sx={{ maxHeight: '480px', mt: '5px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {dataTable.map(each => (
                <StyledCell key={each}>{each}</StyledCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {dataList.map(each => (
              <StyledRow key={each.productionId}>
                <StyledCell>{changeDateDash(each.instructionAt)}</StyledCell>
                <StyledCell>{`${each.workWeek}-${each.workNumber}`}</StyledCell>
                <StyledCell sx={{ fontWeight: 700 }}>
                  {`${each.currentState} (${each[workerName(each.currentState)]})`}
                </StyledCell>
                <StyledCell>{each.recipeNum}</StyledCell>
                <StyledCell>{each.companyBrand}</StyledCell>
                <StyledCell>{`(${each.inhaleType})${each.name}`}</StyledCell>
                <StyledCell>{each.totalWeight}</StyledCell>
                <StyledCell>{changeDateDash(each.pickupAt) || '작업중'}</StyledCell>
                <StyledCell>
                  {each.instTime === '작업중'
                    ? '작업중'
                    : `(지시) ${each.instTime}h / (생산) ${each.combTime}h`}
                </StyledCell>
                <StyledCell>
                  <Button onClick={() => openDeleteModal(each)} variant="text">
                    삭제
                  </Button>
                </StyledCell>
                <StyledCell>
                  <Button
                    onClick={() => openWorkCompletion(each.productionId)}
                    sx={{ width: '70px' }}
                  >
                    보기
                  </Button>
                </StyledCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination page={page} setPage={setPage} totalItem={totalItem} itemNumber={historyNumber} />

      {workCompletionSwitch && (
        <PaperModalForm modalSwitch={workCompletionSwitch} setModalSwitch={setWorkCompletionSwitch}>
          <WorkCompletion selectedProductionId={selectedProductionId} />
        </PaperModalForm>
      )}

      {deleteConfirmSwitch && (
        <ConfirmModal
          modalSwitch={deleteConfirmSwitch}
          setModalSwitch={setDeleteConfirmSwitch}
          title="삭제 확인"
          contents="정말 해당 작업을 삭제하시겠습니까?"
          func={deleteProduction}
        />
      )}
    </div>
  );
}
