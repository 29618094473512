import React, { useState, useContext } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Button,
  TableContainer,
  Paper,
} from '@mui/material';
import RecipeFilter from '../../../component/ui/RecipeFilter';
import { StyledRow, StyledCell } from '../../../component/style/TableStyle';
import Pagination from '../../../component/Pagination';
import { MainTitle } from '../../../component/style/TextStyle';
import DetailInputModal from './DetailInputModal';
import { recipeSearchHook } from '../../../module/useEffectHooks';
import { recipeNumber } from '../../../component/commonVariable';
import { LoginContext } from '../../../module/ContextAPI';

export default function Instruction() {
  const { userAuthority } = useContext(LoginContext);

  // 검색 필터
  const [categoryFilter, setCategoryFilter] = useState({
    category: '',
    companyBrand: '',
    flavorType: '',
    flavorDetail: '',
  });
  const [searchFilter, setSearchFilter] = useState({
    searchFilter: '레시피 번호',
    searchInput: '',
  });

  // 레시피 데이터 및 페이지
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  const [refreshSwitch, setRefreshSwitch] = useState(true);

  recipeSearchHook(categoryFilter, searchFilter, setDataList, page, setTotalItem, refreshSwitch);

  const categoryFilterSearch = () => {
    setRefreshSwitch(!refreshSwitch);
  };

  const searchFilterSearch = () => {
    setRefreshSwitch(!refreshSwitch);
  };

  const [detailModalSwitch, setDetailModalSwitch] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(0);

  const dataTable = [
    '레시피 번호',
    '분류',
    '회사/브랜드',
    '(유형)레시피명',
    '향 종류',
    '향 상세',
    '쿨링',
    '용량',
    '상태',
    '비고',
    '선택하기',
  ];

  const openDetailModal = (id, status) => {
    // console.log(userAuthority);
    if (userAuthority === 'D') {
      alert('권한이 없습니다');
    } else {
      // eslint-disable-next-line
      if (status === '단종') {
        alert('단종된 레시피입니다!');
      } else {
        setSelectedRecipe(id);
        setDetailModalSwitch(true);
      }
    }
  };

  return (
    <div>
      <RecipeFilter
        categoryFilter={categoryFilter}
        setCategoryFilter={setCategoryFilter}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        categoryFilterSearch={categoryFilterSearch}
        searchFilterSearch={searchFilterSearch}
      />

      <MainTitle>레시피 리스트</MainTitle>

      <TableContainer component={Paper} sx={{ maxHeight: '480px', mt: '5px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {dataTable.map(each => (
                <StyledCell key={each}>{each}</StyledCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {dataList.map(each => (
              <StyledRow
                key={each.recipeId}
                // eslint-disable-next-line
                sx={[
                  each.status === '단종' && {
                    backgroundColor: 'rgba(100, 100, 100, 0.17) !important',
                  },
                ]}
              >
                <StyledCell>{each.recipeNum}</StyledCell>
                <StyledCell>{each.category}</StyledCell>
                <StyledCell>{each.companyBrand}</StyledCell>
                <StyledCell>{`(${each.inhaleType})${each.name}`}</StyledCell>
                <StyledCell>{each.flavorType}</StyledCell>
                <StyledCell>{each.flavorDetail}</StyledCell>
                <StyledCell>{each.cooling}</StyledCell>
                <StyledCell>{each.containerVolume}ml</StyledCell>
                <StyledCell sx={[each.status === '단종' && { fontWeight: 700, color: '#C21807' }]}>
                  {each.status}
                </StyledCell>
                <StyledCell>{each.note}</StyledCell>
                <StyledCell>
                  <Button
                    onClick={() => openDetailModal(each.recipeId, each.status)}
                    variant="outlined"
                    sx={{ width: '70px' }}
                  >
                    선택
                  </Button>
                </StyledCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination page={page} setPage={setPage} totalItem={totalItem} itemNumber={recipeNumber} />

      {detailModalSwitch && (
        <DetailInputModal
          modalSwitch={detailModalSwitch}
          setModalSwitch={setDetailModalSwitch}
          selectedRecipe={selectedRecipe}
        />
      )}
    </div>
  );
}
