import React, { useState } from 'react';
import axios from 'axios';
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
} from '@mui/material';
import { MainTitle } from '../../component/style/TextStyle';
import { StyledRow, StyledCell } from '../../component/style/TableStyle';
import { userDataHook } from '../../module/useEffectHooks';
import { changeDateDash } from '../../component/commonFunction';
import UserAddEditModal from './UserAddEditModal';
import { url } from '../../component/commonVariable';
import ConfirmModal from '../../component/ui/ConfirmModal';

export default function User() {
  const dataTable = [
    'No',
    '아이디',
    '이름',
    '부서',
    '권한',
    '메모',
    '생성일자',
    '수정',
    '비밀번호 초기화',
  ];

  const [dataList, setDataList] = useState([]);

  // 회원 추가/수정
  const [addEditModalSwitch, setAddEditModalSwitch] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState({});

  const [refreshSwitch, setRefreshSwitch] = useState(true);

  // 비밀번호 초기화
  const [resetModalSwitch, setResetModalSwitch] = useState(false);

  const openResetModal = data => {
    setEditData(data);
    setResetModalSwitch(true);
  };

  const resetPassword = () => {
    const body = { newPassword: '0000' };

    axios.post(`${url}/user/account/changePassword/${editData.userId}`, body).then(() => {
      alert('비밀번호가 0000으로 초기화되었습니다.');
      setResetModalSwitch(false);
    });
  };

  userDataHook(setDataList, refreshSwitch);

  const openAddEditModal = (mode, data) => {
    if (mode === 'add') {
      setIsEditMode(false);
      setEditData({});
      setAddEditModalSwitch(true);
    } else {
      setIsEditMode(true);
      setEditData(data);
      setAddEditModalSwitch(true);
    }
  };

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" alignContent="center">
        <MainTitle>계정 목록</MainTitle>
        <Button onClick={() => openAddEditModal('add')} sx={{ width: '90px', height: '30px' }}>
          계정 추가
        </Button>
      </Grid>

      <TableContainer component={Paper} sx={{ maxHeight: '480px', mt: '5px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {dataTable.map(each => (
                <StyledCell key={each}>{each}</StyledCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {dataList.map((each, index) => (
              <StyledRow key={each.userId}>
                <StyledCell>{index + 1}</StyledCell>
                <StyledCell>{each.id}</StyledCell>
                <StyledCell>{each.name}</StyledCell>
                <StyledCell>{each.department}</StyledCell>
                <StyledCell>{each.authority}</StyledCell>
                <StyledCell>{each.note}</StyledCell>
                <StyledCell>{changeDateDash(each.createdAt)}</StyledCell>
                <StyledCell>
                  <Button
                    onClick={() => openAddEditModal('edit', each)}
                    variant="outlined"
                    sx={{ width: '70px' }}
                  >
                    수정하기
                  </Button>
                </StyledCell>
                <StyledCell>
                  <Button
                    onClick={() => openResetModal(each)}
                    variant="outlined"
                    sx={{ width: '70px' }}
                  >
                    초기화
                  </Button>
                </StyledCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {addEditModalSwitch && (
        <UserAddEditModal
          modalSwitch={addEditModalSwitch}
          setModalSwitch={setAddEditModalSwitch}
          isEditMode={isEditMode}
          editData={editData}
          refreshSwitch={refreshSwitch}
          setRefreshSwitch={setRefreshSwitch}
        />
      )}

      {resetModalSwitch && (
        <ConfirmModal
          modalSwitch={resetModalSwitch}
          setModalSwitch={setResetModalSwitch}
          title="초기화 확인"
          contents="비밀번호를 0000으로 초기화시키겠습니까?"
          func={resetPassword}
        />
      )}
    </div>
  );
}
