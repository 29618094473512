/** api 통신 주소 */
export const url =
  process.env.REACT_APP_ENV === 'dev' ? 'http://localhost:9988' : 'http://api.cntdkr.kr';

// ㅇ 아이템 개수
export const recipeNumber = 12;
export const additiveNumber = 12;
export const compositionNumber = 12;
export const historyNumber = 12;
export const inventoryHistoryNumber = 12;

/** 자동완성 목록 */
export const materialCategoryList = ['기타', '스위트너', '쿨링', '향료'];
