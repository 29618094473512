// eslint 제거
/* eslint-disable */
import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Grid,
  Button,
  Stack,
  Box,
  Modal,
  Typography,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { pretendardBold, pretendardRegular, pretendardMedium } from '../pdfFont';
import { DocStyledRow, DocStyledCell } from '../style/TableStyle';
import { MainTitle } from '../style/TextStyle';
import { CoaFormHook } from '../../module/useEffectHooks';

export default function COAForm({ recipeId }) {
  const COAInfo = ['No', '화학물질명(Chemical Name)', 'Cas No.', '함량(Content)'];

  const [recipeInfoData, setRecipeInfoData] = useState({});
  const [dataList, setDataList] = useState([]);

  const [addPdfText, setAddPdfText] = useState({
    englishProductName: '',
    phone: '',
    email: '',
    name: '',
    department: '',
    date: '',
  });
  const [addPdfTextModal, setAddPdfTextModal] = useState(false);

  CoaFormHook(recipeId, setRecipeInfoData, setDataList);

  const captureImg = () => {
    const capDiv = document.getElementById('captureDiv');
    html2canvas(capDiv).then(canvas => {
      const link = document.createElement('a');
      link.download = `COA_${recipeInfoData.name}`;
      link.href = canvas.toDataURL();
      document.body.appendChild(link);
      link.click();
    });
  };

  // const capturePdf = () => {
  //   html2canvas(document.getElementById('captureDiv'), {
  //     scale: 9,
  //   }).then(function (canvas) {
  //     const filename = `COA_${recipeInfoData.name}.pdf`;
  //     // eslint-disable-next-line
  //     const doc = new jsPDF('p', 'mm', 'a4');

  //     const imgData = canvas.toDataURL('image/jpeg');
  //     const imgWidth = 210;
  //     const pageHeight = 297;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     let heightLeft = imgHeight;
  //     let position = 0;
  //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       doc.addPage();
  //       doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }
  //     doc.save(filename);
  //   });
  // };

  // 데이터로 pdf 만든 후 저장

  const openAddPdfTextModal = () => {
    setAddPdfTextModal(true);
  };

  const savePdf = async () => {
    // addPdfText에 값이 없으면 alert
    if (
      Object.values(addPdfText).some(each => {
        if (each === '') {
          alert('모든 항목을 입력해주세요.');
          return true;
        }
      })
    ) {
    } else {
      const pageNum = await generatePDF('count');
      await generatePDF('save', pageNum);
      setAddPdfTextModal(false);
    }
  };

  const generatePDF = (mode, pageNum) => {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      // lineHeight: 1.5,
    });

    // 폰트 세팅
    doc.addFileToVFS('Pretendard-Regular.ttf', pretendardRegular);
    doc.addFont('Pretendard-Regular.ttf', 'Pretendard', 'normal');
    doc.addFileToVFS('Pretendard-Medium.ttf', pretendardMedium);
    doc.addFont('Pretendard-Medium.ttf', 'Pretendard', 'medium');
    doc.addFileToVFS('Pretendard-Bold.ttf', pretendardBold);
    doc.addFont('Pretendard-Bold.ttf', 'Pretendard', 'bold');

    doc.setFont('Pretendard', 'normal');

    const entireWidth = doc.internal.pageSize.getWidth() - 20;
    doc.setTextColor('#333333');

    // 워터마크 이미지
    const imageUrl = '/watermark.png';
    const watermarkHeight = 60;
    const watermarkWidth = (1272 / 474) * watermarkHeight;
    const addWatermark = () => {
      doc.addImage(
        imageUrl,
        'PNG',
        (doc.internal.pageSize.getWidth() - watermarkWidth) / 2,
        (doc.internal.pageSize.getHeight() - watermarkHeight) / 2,
        watermarkWidth,
        watermarkHeight,
        '',
        'NONE',
      );
    };

    // 머리말 꼬리말
    const headerFooterHeight = 10;
    const addHeaderFooter = i => {
      // 머리말 및 꼬리말의 배경색 설정
      doc.setFillColor('#3F562A');
      // 머리말 박스 그리기
      doc.rect(0, 0, doc.internal.pageSize.getWidth(), headerFooterHeight, 'F');
      // 꼬리말 박스 그리기
      doc.rect(
        0,
        doc.internal.pageSize.getHeight() - headerFooterHeight,
        doc.internal.pageSize.getWidth(),
        headerFooterHeight,
        'F',
      );

      // 머리말 텍스트 설정
      doc.setFontSize(10);
      doc.setTextColor(255, 255, 255); // 하얀색
      // 왼쪽 텍스트
      doc.text('(주)커넥티드코리아 Connected Korea Inc.', 5, 6);
      // 오른쪽 텍스트 (웹사이트 주소)
      const rightText = 'www.cntdkorea.com';
      const rightTextWidth = doc.getTextWidth(rightText);
      doc.text(rightText, doc.internal.pageSize.getWidth() - rightTextWidth - 5, 6);

      // 꼬리말 텍스트 설정
      doc.setFontSize(9);
      doc.setTextColor(255, 255, 255); // 하얀색
      const footerText = `주소(Address): 경기 양주시 화합로1710번길 12 987-1 양주 옥정듀클래스(12, Hwahap-ro 1710beon-gil, Yangju-si, Gyeonggi-do, Republic of Korea)`;
      // 꼬리말 텍스트가 너무 길면, 페이지 너비에 맞게 조정하거나 글꼴 크기를 줄이는 것을 고려하세요.
      doc.text(footerText, 5, doc.internal.pageSize.getHeight() - 4);

      // 현재 페이지 번호 추가. 정 가운데
      doc.setTextColor('#333333');
      doc.text(
        `${i}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 15,
      );
    };

    if (mode !== 'count') {
      // 페이지 추가
      for (let i = 1; i <= pageNum; i++) {
        if (i !== pageNum) {
          doc.addPage();
        }
        doc.setPage(i);
        addHeaderFooter(i);
        addWatermark();
      }

      // 다시 1페이지로 돌아옴.
      doc.setPage(1);
    }

    doc.setTextColor('#333333');
    // 연락처
    doc.setFontSize(9);
    doc.text(`TEL      ${addPdfText.phone}`, doc.internal.pageSize.getWidth() - 55, 17);
    doc.text(`Email    ${addPdfText.email}`, doc.internal.pageSize.getWidth() - 55, 22);

    // 로고 + 성분 분석표
    doc.setFillColor('#EAEAEA'); // RGB 색상 코드로 변환
    // 박스 그리기
    doc.rect(10, 26, doc.internal.pageSize.getWidth() - 20, 20, 'F');
    doc.addImage('/cntd.png', 'PNG', 12, 32, 26.82, 8.7, '', 'NONE');
    doc.setFont('Pretendard', 'medium');
    doc.setFontSize(18);
    doc.text('성분분석표\nCOA(Certificate of Analysis)', doc.internal.pageSize.getWidth() / 2, 34, {
      align: 'center',
    });

    // 제품명
    doc.setFont('Pretendard', 'medium');
    doc.setFontSize(15);
    doc.text(
      `제품명(Product Name) : ${recipeInfoData.name} (${addPdfText.englishProductName})`,
      10,
      58, // doc.autoTable.previous.finalY + 18,
    );

    // 테이블 생성
    doc.setFont('Pretendard', 'normal');
    autoTable(doc, {
      startY: 63, // doc.autoTable.previous.finalY + 23,
      html: '#recipe',
      styles: {
        font: 'Pretendard',
        fontStyle: 'normal',
        lineWidth: 0.1, // 테두리 두께
        lineColor: [51, 51, 51],
        fillColor: false,
      },
      didParseCell: function (data) {
        data.cell.styles.textColor = '#333333';
        if (data.column.index === 0) {
          // 헤더나 첫 번째 행의 셀
          data.cell.styles.valign = 'middle'; // 가운데 세로 정렬
          data.cell.styles.halign = 'center'; // 가운데 가로 정렬
        }
      },
      theme: 'plain',
      showHead: 'firstPage',
      margin: { left: 10, right: 10 },
      headStyles: {
        cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
        fontStyle: 'bold',
      },
      bodyStyles: {
        cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
      },
      columnStyles: {
        0: { cellWidth: (entireWidth / 100) * 8, fontStyle: 'bold' },
      },
    });

    // 합계
    doc.setFontSize(14);
    doc.text(
      '합계(TOTAL) 100%',
      doc.internal.pageSize.getWidth() - 10,
      doc.autoTable.previous.finalY + 8,
      {
        align: 'right',
      },
    );

    // 담당자 등
    doc.setFontSize(12);
    doc.text(
      `Best Regards,\n담당자명(Name):   ${addPdfText.name}     (signature or seal)\n부서명(Name of Department):   ${addPdfText.department}\n발행일(Date):   ${addPdfText.date}`,
      doc.internal.pageSize.getWidth() / 2,
      doc.autoTable.previous.finalY + 18,
      {
        align: 'center',
        lineHeightFactor: 2,
      },
    );

    if (mode === 'count') {
      return doc.internal.getNumberOfPages();
    } else {
      doc.save(`COA_${recipeInfoData.name}.pdf`);
    }
  };

  return (
    <div>
      <Box
        sx={{
          backgroundColor: '#EEEEEE',
          pt: '15px',
          pb: '5px',
          borderTop: '2px solid #333333',
          borderBottom: '1px solid #333333',
          mb: '20px',
        }}
      >
        <MainTitle sx={{ textAlign: 'center', whiteSpace: 'pre-wrap', lineHeight: 1.3 }}>
          {`성분 분석표\nCOA(Certificate Of Analytics)`}
        </MainTitle>
      </Box>

      <MainTitle>제품명 : {recipeInfoData.name}</MainTitle>

      {/* 레시피 기본 정보 부분 */}
      <Table id="recipe" sx={{ border: '2px solid #333333', mt: '10px' }}>
        <TableHead>
          <TableRow>
            {COAInfo.map(each => (
              <DocStyledCell key={each}>{each}</DocStyledCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList.map((each, index) => (
            <DocStyledRow key={each.name}>
              <DocStyledCell>{index + 1}</DocStyledCell>
              <DocStyledCell>{each.name}</DocStyledCell>
              <DocStyledCell>{each.casNum}</DocStyledCell>
              <DocStyledCell>{each.totalPercent}%</DocStyledCell>
            </DocStyledRow>
          ))}
        </TableBody>
      </Table>

      <Grid container direction="row" justifyContent="flex-end" sx={{ mt: '20px' }}>
        <Button
          color="secondary"
          data-html2canvas-ignore="true"
          onClick={openAddPdfTextModal}
          sx={{ width: 100, height: 30, mr: '10px' }}
        >
          PDF로 저장
        </Button>
        <Button data-html2canvas-ignore="true" onClick={captureImg} sx={{ width: 100, height: 30 }}>
          PNG로 저장
        </Button>
      </Grid>

      {addPdfTextModal && (
        <AddPdfTextModal
          modalSwitch={addPdfTextModal}
          setModalSwitch={setAddPdfTextModal}
          addPdfText={addPdfText}
          setAddPdfText={setAddPdfText}
          savePdf={savePdf}
        />
      )}
    </div>
  );
}

COAForm.defaultProps = {
  recipeId: 0,
};

COAForm.propTypes = {
  recipeId: PropTypes.number,
};

function AddPdfTextModal({ modalSwitch, setModalSwitch, addPdfText, setAddPdfText, savePdf }) {
  const addPdfTextList = [
    { label: '영어 제품명', keyName: 'englishProductName' },
    { label: '연락처', keyName: 'phone' },
    { label: '이메일', keyName: 'email' },
    { label: '담당자', keyName: 'name' },
    { label: '부서명', keyName: 'department' },
    { label: '발행일', keyName: 'date' },
  ];

  const onChangeAddPdfText = e => {
    const { name, value } = e.target;
    setAddPdfText({
      ...addPdfText,
      [name]: value,
    });
  };

  const modalClose = () => setModalSwitch(false);

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 350,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 30px 30px 30px',
            outline: 'none',
          }}
        >
          <Typography
            variant="h4"
            color="#333333"
            align="center"
            fontWeight={600}
            fontSize="26px"
            sx={{ mb: '25px' }}
          >
            PDF 내용 입력
          </Typography>

          {addPdfTextList.map(each => (
            <Stack key={each.keyName} direction="row" alignItems="center" sx={{ mb: '10px' }}>
              <Typography color="#333333" fontWeight={500} fontSize={16} sx={{ width: '115px' }}>
                {each.label}
              </Typography>
              <TextField
                fullWidth
                name={each.keyName}
                value={addPdfText[each.keyName]}
                onChange={onChangeAddPdfText}
              />
            </Stack>
          ))}

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '25px' }}>
            <Button
              fullWidth
              variant="contained"
              onClick={savePdf}
              sx={{ height: '34px', fontSize: '16px', mr: '10px' }}
            >
              확인
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="cancel"
              onClick={modalClose}
              sx={{ height: '34px', fontSize: '16px', color: '#FFF' }}
            >
              취소
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
