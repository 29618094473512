import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, Stack, Grid, TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { MainTitle, SubTitle } from '../../../component/style/TextStyle';
import { url } from '../../../component/commonVariable';
import { AutoCompleteComposition } from '../../../component/ui/AutoComplete';
import { changeDateDot, sessionExpireError } from '../../../component/commonFunction';
import ConfirmModal from '../../../component/ui/ConfirmModal';
import { compositionListHook } from '../../../module/useEffectHooks';
import { LoginContext } from '../../../module/ContextAPI';

export default function MaterialAddEditModal({
  modalSwitch,
  setModalSwitch,
  editMaterialId,
  refreshSwitch,
  setRefreshSwitch,
  isEditMode,
}) {
  const { loginProcess } = useContext(LoginContext);
  const materialInfo = [
    [
      { label: '분류', keyName: 'category', width: '90px', maxLength: 10 },
      { label: '제조사', keyName: 'company', width: '90px', maxLength: 20 },
      { label: '이름', keyName: 'name', width: '90px', maxLength: 50 },
    ],
    [
      { label: '코드번호', keyName: 'codeNum', width: '70px', maxLength: 45 },
      { label: '비중', keyName: 'gravity', width: '70px', maxLength: 20 },
      { label: '재고', keyName: 'inventory', width: '70px', maxLength: 20 },
    ],
    [
      { label: '상태', keyName: 'status', width: '40px', maxLength: 10 },
      { label: '메모', keyName: 'note', width: '40px', maxLength: 300 },
    ],
  ];

  // db에 ''를 위한 초기값 (undefined로 등록되거나 아니면 || ''로 일일히 등록해야 하는)
  const [materialInfoData, setMaterialInfoData] = useState({
    category: '',
    company: '',
    name: '',
    codeNum: '',
    gravity: '',
    inventory: '',
    status: '',
    note: '',
  });

  // 첨가물 상세 정보
  const [compositionList, setCompositionList] = useState([]);
  const [compositionOgList, setCompositionOgList] = useState([]);

  const [deleteModalSwitch, setDeleteModalSwitch] = useState(false);

  compositionListHook(
    editMaterialId,
    setMaterialInfoData,
    setCompositionList,
    setCompositionOgList,
  );

  // 원자재 정보 수정
  const onChangeMaterialInfo = e => {
    const { name, value } = e.target;
    const tempInfoInput = { ...materialInfoData };
    tempInfoInput[name] = value;
    setMaterialInfoData(tempInfoInput);
  };

  // 첨가물 상세 목록 추가
  const addMaterial = () => {
    const tempInput = [...compositionList];
    tempInput.push({ name: '', compositionId: '', percentage: '' });
    setCompositionList(tempInput);
  };

  // 각각의 구성물질 투여량
  const onChangeMaterialPercentage = (e, index) => {
    const tempInput = [...compositionList];
    tempInput[index].percentage = e.target.value;
    setCompositionList(tempInput);
  };

  // 하나의 재료 항목 삭제
  const deleteMaterial = index => {
    const tempInput = [...compositionList];
    tempInput.splice(index, 1);
    setCompositionList(tempInput);
  };

  // 데이터 저장/수정
  const saveData = () => {
    const name = compositionList.find(each => each.name === '' || each.name === null);
    const percentage = compositionList.find(each => each.percentage === '');

    // 구성물질 내용 유무 검사
    if (compositionList.length === 0) {
      alert('구성물질 관리 부분을 입력해주세요!');
    } else {
      // 구성물질 상세 내용 유효값 검사
      // eslint-disable-next-line
      if (name) {
        alert(`구성물질 관리 부분\n구성 물질을 선택하지 않은 행이 있습니다.`);
      } else if (percentage) {
        alert(`구성물질 관리 부분\n함량을 입력하지 않은 행이 있습니다.\n\n* ${percentage.name}`);
      } else {
        // 구성물질 100% 확인
        let percentageTotal = 0;
        compositionList.forEach(e => {
          percentageTotal += parseFloat(e.percentage);
        });
        if (percentageTotal < 99.99 || percentageTotal > 100.01) {
          alert(`구성물질의 전체 합이 100%가 아닙니다.\n 현재 값 : ${percentageTotal}`);
        } else {
          // 중복 검사 (중복 이름)
          let isDuplicate = 0;
          let duplicateCheckArray;
          for (let i = 0; i < compositionList.length; i += 1) {
            duplicateCheckArray = compositionList.filter(
              each => each.name === compositionList[i].name,
            );
            if (duplicateCheckArray.length !== 1) {
              isDuplicate = 1;
              break;
            }
          }

          if (isDuplicate === 1) {
            alert(`중복된 재료가 있습니다.\n* ${duplicateCheckArray[0].name}`);
          } else {
            // 수정 시, og 데이터와 비교. 수정, 추가, 삭제 선별.
            if (isEditMode) {
              for (let i = 0; i < compositionList.length; i += 1) {
                const actionCheckArray = compositionOgList.filter(
                  each => each.compositionId === compositionList[i].compositionId,
                );
                // 기존 레시피에는 없는, 새로운 재료
                if (actionCheckArray.length === 0) {
                  compositionList[i].action = 'INSERT';
                  // 같은 재료가 있다 => 업데이트 대상
                } else {
                  compositionList[i].action = 'UPDATE';
                }
              }
            }
            const body = [materialInfoData, ...compositionList];

            // 삭제할 행 추가
            if (isEditMode) {
              for (let i = 0; i < compositionOgList.length; i += 1) {
                const actionCheckArray = compositionList.filter(
                  each => each.compositionId === compositionOgList[i].compositionId,
                );

                if (actionCheckArray.length === 0) {
                  const deleteRow = { ...compositionOgList[i], action: 'DELETE' };
                  body.push(deleteRow);
                }
              }
            }

            const mode = isEditMode ? 'edit' : 'add';
            axios
              .post(`${url}/material/${mode}`, body)
              .then(() => {
                alert('작업이 성공적으로 완료되었습니다');
                setRefreshSwitch(!refreshSwitch);
                modalClose();
              })
              .catch(err => {
                const isError = sessionExpireError(err);
                if (isError) {
                  loginProcess('로그인X');
                  return;
                }
                console.log(err);
                alert('레시피 작업을 하는 중 오류가 발생했습니다\n다시 시도해주세요');
              });
          }
        }
      }
    }
  };

  const deleteRecipe = () => {
    axios.post(`${url}/material/delete/${materialInfoData.materialId}`).then(() => {
      alert('삭제가 성공적으로 완료되었습니다');
      setRefreshSwitch(!refreshSwitch);
      modalClose();
    });
  };

  // 삭제 모달
  const openDeleteModal = () => {
    setDeleteModalSwitch(true);
  };

  // 모달 닫기
  const modalClose = () => {
    setModalSwitch(false);
    setRefreshSwitch(!refreshSwitch);
  };

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 990,
            height: 550,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 60px 50px 60px',
            outline: 'none',
            overflowY: 'scroll',
          }}
        >
          <Typography align="center" fontSize={28} fontWeight={600}>
            원자재 {isEditMode ? '수정' : '추가'}
          </Typography>

          <Box sx={{ height: '1px', backgroundColor: '#C9C9C9', my: '20px' }} />

          {/* 레시피 기본 정보 부분 */}
          <Stack direction="row" spacing="10px">
            <MainTitle sx={{ fontSize: '22px' }}>원자재 정보</MainTitle>
            {isEditMode && (
              <SubTitle sx={{ pt: '5px' }}>{`최종 수정 : ${changeDateDot(
                materialInfoData.updatedAt,
              )} / ${materialInfoData.lastEditor}`}</SubTitle>
            )}
          </Stack>

          <Grid container direction="row" justifyContent="center" spacing="20px">
            {materialInfo.map(eachColumn => (
              <Grid item xs={4} key={`${eachColumn[0].label}_grid`}>
                {eachColumn.map(each => (
                  <Stack
                    direction="row"
                    spacing="10px"
                    key={each.label}
                    sx={{ mb: '5px' }}
                    alignItems="center"
                  >
                    <Box sx={{ width: each.width }}>{each.label}</Box>
                    <TextField
                      name={each.keyName}
                      value={materialInfoData[each.keyName] || ''}
                      onChange={e => onChangeMaterialInfo(e)}
                      type={each.label === '재고' ? 'number' : ''}
                      inputProps={{
                        maxLength: each.maxLength,
                        style: { padding: '7px 0px 7px 13px' },
                      }}
                    />
                  </Stack>
                ))}
              </Grid>
            ))}
          </Grid>

          {/* 구성물질 입력 부분 */}
          <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: '40px' }}>
            <MainTitle sx={{ fontSize: '22px' }}>구성 물질 관리</MainTitle>

            <Button onClick={addMaterial} sx={{ width: '105px' }}>
              구성 물질 추가
            </Button>
          </Grid>

          <Grid container direction="row" justifyContent="flex-start" spacing="10px">
            {compositionList.map((each, index) => (
              // eslint-disable-next-line
              <Grid item xs={6} key={`레시피 상세 입력 ${index}`}>
                <Stack direction="row" alignItems="center" spacing="8px">
                  <AutoCompleteComposition
                    compositionList={compositionList}
                    setCompositionList={setCompositionList}
                    index={index}
                    category={compositionList[index].category}
                    minWidth="280px"
                  />

                  <TextField
                    value={compositionList[index].percentage || ''}
                    onChange={e => onChangeMaterialPercentage(e, index)}
                    type="number"
                    sx={{ width: '100px' }}
                  />

                  <Button onClick={() => deleteMaterial(index)} variant="outlined">
                    삭제
                  </Button>
                </Stack>
              </Grid>
            ))}
          </Grid>

          {/* 최하단 확인 / 취소 */}
          <Grid container justifyContent="center" sx={{ mt: '40px' }}>
            <Button onClick={saveData} sx={{ width: '100px', height: '30px' }}>
              {isEditMode ? '수정하기' : '추가하기'}
            </Button>
            <Button
              onClick={modalClose}
              color="cancel"
              sx={{ width: '100px', height: '30px', color: '#FFF', ml: '10px' }}
            >
              취소
            </Button>

            {isEditMode && (
              <Button
                onClick={openDeleteModal}
                color="red"
                sx={{ width: '100px', height: '30px', color: '#FFF', ml: '10px' }}
              >
                삭제
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>

      {deleteModalSwitch && (
        <ConfirmModal
          modalSwitch={deleteModalSwitch}
          setModalSwitch={setDeleteModalSwitch}
          title="정말 삭제하시겠습니까?"
          contents="원자재의 모든 정보가 삭제됩니다"
          func={deleteRecipe}
        />
      )}
    </div>
  );
}

MaterialAddEditModal.defaultProps = {
  isEditMode: false,
  modalSwitch: true,
  setModalSwitch: () => {},
  editMaterialId: 0,
  refreshSwitch: true,
  setRefreshSwitch: () => {},
};

MaterialAddEditModal.propTypes = {
  isEditMode: PropTypes.bool,
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  editMaterialId: PropTypes.number,
  refreshSwitch: PropTypes.bool,
  setRefreshSwitch: PropTypes.func,
};
