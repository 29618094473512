import React, { useState, useContext } from 'react';
import { Grid, Button, Stack, Box, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import { MainTitle, NormalContentsTitle, SubTitle } from '../../../component/style/TextStyle';
import { changeDateDetail } from '../../../component/commonFunction';
import StatusModal from './StatusModal';
import { statusDataHook } from '../../../module/useEffectHooks';
import { LoginContext } from '../../../module/ContextAPI';
// eslint-disable-next-line
import 'swiper/css';
// eslint-disable-next-line
import 'swiper/css/free-mode';

export default function Status() {
  const { userAuthority } = useContext(LoginContext);

  const currentTime = new Date();
  const statusList = [
    { label: '대기', stateBy: 'instructionBy', stateAt: 'instructionAt' },
    { label: '배합', stateBy: 'combinationBy', stateAt: 'combinationAt' },
    { label: '교반', stateBy: 'stirringBy', stateAt: 'stirringAt' },
    { label: '중탕', stateBy: 'boilingBy', stateAt: 'boilingAt' },
    { label: '교반 종료', stateBy: 'endBy', stateAt: 'endAt' },
    { label: '생산팀 수령', stateBy: 'relaseBy', stateAt: 'relaseAt' },
  ];
  const [dataList, setDataList] = useState([]);

  // 모달창
  const [statusModalSwitch, setStatusModalSwitch] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const { setRefreshSwitch } = statusDataHook(statusList, setDataList);

  const openStateModal = (ind, data) => {
    if (userAuthority === 'D' && ind < 4) {
      alert('권한이 없습니다');
    } else if (userAuthority === 'C' && ind === 5) {
      alert('권한이 없습니다');
    } else if (userAuthority === 'B' && ind > 0) {
      alert('권한이 없습니다');
    } else {
      const tempData = { statusIndex: ind, ...data };
      setSelectedData(tempData);
      setStatusModalSwitch(true);
    }
  };

  const refreshData = () => {
    setRefreshSwitch(prev => !prev);
  };

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <MainTitle>생산 현황</MainTitle>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing="10px">
          <SubTitle>{changeDateDetail(currentTime)}</SubTitle>
          <Button onClick={refreshData} sx={{ width: '80px', height: '30px' }}>
            새로고침
          </Button>
        </Stack>
      </Grid>

      <Box
        sx={{
          backgroundColor: '#F3F6FD',
          px: '20px',
          py: '20px',
          borderRadius: '12px',
          mt: '10px',
          cursor: 'pointer',
          userSelect: 'none',
        }}
      >
        <Swiper
          slidesPerView={2.5}
          centeredSlidesBounds
          freeMode
          setWrapperSize
          modules={[FreeMode]}
          spaceBetween={40}
        >
          {statusList.map((eachState, statusIndex) => (
            <SwiperSlide key={eachState.label}>
              <Grid container direction="row" justifyContent="flex-start">
                <NormalContentsTitle sx={{ fontWeight: 600, fontSize: '16px', mb: '10px' }}>
                  {eachState.label}
                </NormalContentsTitle>
              </Grid>

              {dataList.length !== 0 && (
                <Grid container spacing="13px">
                  {dataList[statusIndex].map(each => (
                    <Grid item xs={6} key={each.productionId}>
                      <Grid
                        onClick={() => openStateModal(statusIndex, each)}
                        container
                        sx={{
                          backgroundColor: '#DEE5FA',
                          borderRadius: '12px',
                          border: '1px solid #A9A9A9',
                          p: '12px',
                        }}
                      >
                        <Typography
                          component="div"
                          fontSize="11px"
                          lineHeight={1.4}
                          align="left"
                          fontWeight={500}
                        >
                          <Box>{each[eachState.stateBy]}</Box>
                          <Box>{changeDateDetail(each[eachState.stateAt])}</Box>
                          <Box>{`${each.workWeek}-${each.workNumber}`}</Box>
                          <Box>{each.companyBrand}</Box>
                          <Box>{`(${each.inhaleType})${each.name}`}</Box>
                          <Box>
                            {each.changeTotalWeight
                              ? `(생산량 변동) ${each.changeTotalWeight}`
                              : each.totalWeight}
                            g
                          </Box>
                          <Box sx={{ mt: '10px' }}>메모 : {each.note}</Box>
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      {statusModalSwitch && (
        <StatusModal
          modalSwitch={statusModalSwitch}
          setModalSwitch={setStatusModalSwitch}
          selectedData={selectedData}
          setRefreshSwitch={setRefreshSwitch}
        />
      )}
    </div>
  );
}
