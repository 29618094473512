import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { url } from '../commonVariable';

export function AutoCompleteMaterial({
  recipeDetailList,
  setRecipeDetailList,
  index,
  category,
  minWidth,
  enterFunc,
}) {
  const [searchInput, setSearchInput] = useState('');
  const [materialList, setMaterialList] = useState([]);

  // 자동완성 (n글자 이상 시 검색)
  useEffect(() => {
    if (searchInput.length >= 2) {
      axios
        .get(`${url}/common/autoComplete/material/${category}/${searchInput}`)
        .then(result => {
          // console.log(result.data);
          setMaterialList(result.data);
        })
        .catch(() => {
          console.log('실패했습니다');
        });
    }
  }, [searchInput]);

  const onChangeInput = e => {
    setSearchInput(e.target.value);
  };

  const onChangeAutoComplete = (e, value) => {
    if (value) {
      const tempInput = [...recipeDetailList];
      tempInput[index].materialId = value.materialId;
      tempInput[index].name = value.name;
      setRecipeDetailList(tempInput);
    }
  };

  const onCheckEnter = e => {
    if (e.key === 'Enter') {
      enterFunc();
    }
  };

  return (
    <Autocomplete
      // 받은 데이터들 중 corp_name을 목록으로 보여주는 옵션 (default - option.label)
      getOptionLabel={option => option.name || ''}
      // 없으면 warning
      disableClearable
      isOptionEqualToValue={(option, value) => option.materialId === value.materialId}
      onChange={(e, value) => onChangeAutoComplete(e, value)}
      options={materialList}
      value={recipeDetailList[index].materialId ? recipeDetailList[index] : null}
      sx={{ minWidth }}
      renderOption={(props, option) => {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Box {...props} key={option.materialId}>
            {option.name}
          </Box>
        );
      }}
      renderInput={params => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          onChange={onChangeInput}
          value={searchInput}
          onKeyPress={onCheckEnter}
          size="small"
        />
      )}
    />
  );
}

AutoCompleteMaterial.defaultProps = {
  category: '기타',
  index: 0,
  recipeDetailList: [],
  setRecipeDetailList: () => {},
  textFieldValue: '',
  minWidth: '120px',
  enterFunc: () => {},
};

AutoCompleteMaterial.propTypes = {
  category: PropTypes.string,
  index: PropTypes.number,
  // eslint-disable-next-line
  recipeDetailList: PropTypes.array,
  setRecipeDetailList: PropTypes.func,
  textFieldValue: PropTypes.string,
  minWidth: PropTypes.string,
  enterFunc: PropTypes.func,
};

// 자동완성 검색필터 (분류, 회사/브랜드, 향 종류, 향 상세)
export function AutoCompleteCategoryFilter({
  categoryFilter,
  setCategoryFilter,
  categoryKeyName,
  autoCompleteResetSwitch,
  minWidth,
  enterFunc,
}) {
  const [searchInput, setSearchInput] = useState('');
  const [dataList, setDataList] = useState([]);
  const [autocompleteText, setAutoCompleteText] = useState(null);

  // 자동완성 (n글자 이상 시 검색)
  useEffect(() => {
    if (searchInput.length === 0) {
      axios
        .get(`${url}/common/autoComplete/searchFilterAll/${categoryKeyName}`)
        .then(result => {
          // console.log(result.data);
          setDataList(result.data);
        })
        .catch(() => {
          console.log('실패했습니다');
        });
    } else if (searchInput.length >= 2) {
      axios
        .get(`${url}/common/autoComplete/searchFilter/${categoryKeyName}/${searchInput}`)
        .then(result => {
          // console.log(result.data);
          setDataList(result.data);
        })
        .catch(() => {
          console.log('실패했습니다');
        });
    }
  }, [searchInput]);

  // 상위 컴포넌트에서 초기화를 위한 훅 (!clearSwitch를 통해 초기화)
  useEffect(() => {
    autoCompleteReset();
  }, [autoCompleteResetSwitch]);

  const onChangeInput = e => {
    setSearchInput(e.target.value);
  };

  const onChangeAutoComplete = (e, value) => {
    setAutoCompleteText(value);
    if (value) {
      const tempObj = { ...categoryFilter };
      tempObj[categoryKeyName] = value.name;
      setCategoryFilter(tempObj);
    }
  };

  // 실제 값은 recipeFilter에서 초기화. 여기서는 텍스트만 초기화
  const autoCompleteReset = () => {
    setAutoCompleteText('');
  };

  const onCheckEnter = e => {
    if (e.key === 'Enter') {
      enterFunc();
    }
  };

  return (
    <Autocomplete
      // 받은 데이터들 중 corp_name을 목록으로 보여주는 옵션 (default - option.label)
      getOptionLabel={option => option.name || ''}
      // 없으면 warning
      disableClearable
      isOptionEqualToValue={(option, value) => option.name === value.name}
      onChange={(e, value) => onChangeAutoComplete(e, value)}
      options={dataList}
      value={autocompleteText || null}
      sx={{ minWidth }}
      renderOption={(props, option) => {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Box {...props} key={option.name}>
            {option.name}
          </Box>
        );
      }}
      renderInput={params => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          onChange={onChangeInput}
          value={searchInput}
          onKeyPress={onCheckEnter}
          size="small"
        />
      )}
    />
  );
}

AutoCompleteCategoryFilter.defaultProps = {
  categoryFilter: {},
  setCategoryFilter: () => {},
  categoryKeyName: '기타',
  autoCompleteResetSwitch: true,
  minWidth: '120px',
  enterFunc: () => {},
};

AutoCompleteCategoryFilter.propTypes = {
  categoryFilter: PropTypes.objectOf(PropTypes.string),
  setCategoryFilter: PropTypes.func,
  categoryKeyName: PropTypes.string,
  autoCompleteResetSwitch: PropTypes.bool,
  minWidth: PropTypes.string,
  enterFunc: PropTypes.func,
};

export function AutoCompleteComposition({
  compositionList,
  setCompositionList,
  index,
  minWidth,
  enterFunc,
}) {
  const [searchInput, setSearchInput] = useState('');
  const [autoCompleteList, setAutoCompleteList] = useState([]);

  // 자동완성 (n글자 이상 시 검색)
  useEffect(() => {
    if (searchInput.length >= 2) {
      axios
        .get(`${url}/common/autoComplete/composition/${searchInput}`)
        .then(result => {
          // console.log(result.data);
          setAutoCompleteList(result.data);
        })
        .catch(() => {
          console.log('실패했습니다');
        });
    }
  }, [searchInput]);

  const onChangeInput = e => {
    setSearchInput(e.target.value);
  };

  const onChangeAutoComplete = (e, value) => {
    if (value) {
      const tempInput = [...compositionList];
      tempInput[index].compositionId = value.compositionId;
      tempInput[index].name = value.name;
      setCompositionList(tempInput);
    }
  };

  const onCheckEnter = e => {
    if (e.key === 'Enter') {
      enterFunc();
    }
  };

  return (
    <Autocomplete
      // 받은 데이터들 중 corp_name을 목록으로 보여주는 옵션 (default - option.label)
      getOptionLabel={option => option.name || ''}
      // 없으면 warning
      disableClearable
      isOptionEqualToValue={(option, value) => option.compositionId === value.compositionId}
      onChange={(e, value) => onChangeAutoComplete(e, value)}
      options={autoCompleteList}
      value={compositionList[index].compositionId ? compositionList[index] : null}
      sx={{ minWidth }}
      renderOption={(props, option) => {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Box {...props} key={option.compositionId}>
            {option.name}
          </Box>
        );
      }}
      renderInput={params => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          onChange={onChangeInput}
          value={searchInput}
          onKeyPress={onCheckEnter}
          size="small"
        />
      )}
    />
  );
}

AutoCompleteComposition.defaultProps = {
  category: '기타',
  index: 0,
  compositionList: [],
  setCompositionList: () => {},
  textFieldValue: '',
  minWidth: '120px',
  enterFunc: () => {},
};

AutoCompleteComposition.propTypes = {
  category: PropTypes.string,
  index: PropTypes.number,
  // eslint-disable-next-line
  compositionList: PropTypes.array,
  setCompositionList: PropTypes.func,
  textFieldValue: PropTypes.string,
  minWidth: PropTypes.string,
  enterFunc: PropTypes.func,
};

// 자동완성 검색필터 - 첨가물 (분류, 제조사)
export function AutoCompleteAdditive({
  onChangeSearchValue,
  categoryKeyName,
  autoCompleteResetSwitch,
  minWidth,
  enterFunc,
}) {
  const [searchInput, setSearchInput] = useState('');
  const [dataList, setDataList] = useState([]);
  const [autocompleteText, setAutoCompleteText] = useState(null);

  // 자동완성 (n글자 이상 시 검색)
  useEffect(() => {
    if (searchInput.length === 0) {
      axios
        .get(`${url}/common/autoComplete/additiveAll/${categoryKeyName}`)
        .then(result => {
          // console.log(result.data);
          setDataList(result.data);
        })
        .catch(() => {
          console.log('실패했습니다');
        });
    } else if (searchInput.length >= 2) {
      axios
        .get(`${url}/common/autoComplete/additive/${categoryKeyName}/${searchInput}`)
        .then(result => {
          // console.log(result.data);
          setDataList(result.data);
        })
        .catch(() => {
          console.log('실패했습니다');
        });
    }
  }, [searchInput, categoryKeyName]);

  // 상위 컴포넌트에서 초기화를 위한 훅 (!clearSwitch를 통해 초기화)
  useEffect(() => {
    autoCompleteReset();
  }, [autoCompleteResetSwitch, categoryKeyName]);

  const onChangeInput = e => {
    setSearchInput(e.target.value);
  };

  const onChangeAutoComplete = (e, value) => {
    setAutoCompleteText(value);
    if (value) {
      onChangeSearchValue(value.name);
    }
  };

  // 실제 값은 recipeFilter에서 초기화. 여기서는 텍스트만 초기화
  const autoCompleteReset = () => {
    setAutoCompleteText('');
  };

  const onCheckEnter = e => {
    if (e.key === 'Enter') {
      enterFunc();
    }
  };

  return (
    <Autocomplete
      // 받은 데이터들 중 corp_name을 목록으로 보여주는 옵션 (default - option.label)
      getOptionLabel={option => option.name || ''}
      // 없으면 warning
      disableClearable
      isOptionEqualToValue={(option, value) => option.name === value.name}
      onChange={(e, value) => onChangeAutoComplete(e, value)}
      options={dataList}
      value={autocompleteText || null}
      sx={{ minWidth }}
      renderOption={(props, option) => {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Box {...props} key={option.name}>
            {option.name}
          </Box>
        );
      }}
      renderInput={params => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          onChange={onChangeInput}
          value={searchInput}
          onKeyPress={onCheckEnter}
          size="small"
        />
      )}
    />
  );
}

AutoCompleteAdditive.defaultProps = {
  categoryKeyName: '기타',
  autoCompleteResetSwitch: true,
  minWidth: '120px',
  enterFunc: () => {},
  onChangeSearchValue: () => {},
};

AutoCompleteAdditive.propTypes = {
  categoryKeyName: PropTypes.string,
  autoCompleteResetSwitch: PropTypes.bool,
  minWidth: PropTypes.string,
  enterFunc: PropTypes.func,
  onChangeSearchValue: PropTypes.func,
};
