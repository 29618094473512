import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import PropTypes from 'prop-types';
import { StyledRow, StyledCell } from '../../../component/style/TableStyle';
import { monthlyUsageDataHook } from '../../../module/useEffectHooks';

export default function MonthlyUsage({ modalSwitch, setModalSwitch, materialId }) {
  const dataTable = ['월', '사용량'];

  const [usageDataList, setUsageDataList] = useState([]);
  monthlyUsageDataHook(materialId, setUsageDataList);

  // 모달 닫기
  const modalClose = () => {
    setModalSwitch(false);
  };

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 400,
            maxHeight: 550,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 60px 50px 60px',
            outline: 'none',
            overflowY: 'scroll',
          }}
        >
          <Typography align="center" fontSize={28} fontWeight={600}>
            월별 사용량 {usageDataList[0] && `- ${usageDataList[0].name}`}
          </Typography>

          <Box sx={{ height: '1px', backgroundColor: '#C9C9C9', mt: '15px', mb: '20px' }} />

          <TableContainer component={Paper} sx={{ maxHeight: '480px', mt: '5px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {dataTable.map(each => (
                    <StyledCell key={each}>{each}</StyledCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {usageDataList.map(each => (
                  <StyledRow key={each.month}>
                    <StyledCell width="50%">{each.month}</StyledCell>
                    <StyledCell sx={{ fontWeight: 600, py: '10px !important' }}>
                      {each.monthlyUsage}
                    </StyledCell>
                  </StyledRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* 최하단 확인 / 취소 */}
          <Grid container justifyContent="center" sx={{ mt: '40px' }}>
            <Button
              onClick={modalClose}
              color="cancel"
              sx={{ width: '100px', height: '30px', color: '#FFF', ml: '10px' }}
            >
              닫기
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

MonthlyUsage.defaultProps = {
  modalSwitch: true,
  setModalSwitch: () => {},
  materialId: 0,
};

MonthlyUsage.propTypes = {
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
  materialId: PropTypes.number,
};
