import { useEffect, useState } from 'react';
import axios from 'axios';
import { url } from '../component/commonVariable';
import { changeDateDayName } from '../component/commonFunction';

// ㅇ 대시보드
/** 대시보드 향료 목록 */
export function dashboardFlavorHook(setDataList, refreshSwitch) {
  useEffect(() => {
    axios.get(`${url}/dashboard/flavor/getList`).then(result => {
      // console.log(result.data, '보유 향료 목록');
      setDataList(result.data);
    });
  }, [refreshSwitch]);
}

/** 대시보드 보유 레시피 목록 */
export function dashboardRecipeHook(setDataList, refreshSwitch) {
  useEffect(() => {
    axios.get(`${url}/dashboard/recipe/getList`).then(result => {
      // console.log(result.data, '보유 레시피');
      setDataList(result.data);
    });
  }, [refreshSwitch]);
}

/** 대시보드 생산 현황 목록 */
export function dashboardProductionHook(setDataList, refreshSwitch) {
  useEffect(() => {
    axios.get(`${url}/dashboard/production/getList`).then(result => {
      // console.log(result.data, '생산현황');
      setDataList(result.data);
    });
  }, [refreshSwitch]);
}

/** 대시보드 그래프 데이터 */
export function dashboardGraph(setDataList, period) {
  useEffect(() => {
    axios.get(`${url}/dashboard/graphData/getList/${period}`).then(result => {
      // console.log(result.data, '그래프 데이터');
      const tempArr = [];
      result.data.forEach(each => {
        const tempData = { ...each };
        tempData.date = changeDateDayName(each.date);
        tempArr.push(tempData);
      });
      setDataList(tempArr);
    });
  }, [period]);
}

// ㅇ 레시피
/** 레시피 데이터 검색 */
export function recipeSearchHook(
  categoryFilter,
  searchFilter,
  setDataList,
  page,
  setTotalItem,
  refreshSwitch,
) {
  useEffect(() => {
    // 검색어가 없는 경우 => 필터로만 검색
    if (searchFilter.searchInput === '') {
      axios.get(`${url}/recipe/getList/normal/${page}`, { params: categoryFilter }).then(result => {
        // console.log(result.data);
        setDataList(result.data);
      });
      // 검색값이 있는 경우 필터 + 검색어
    } else {
      axios
        .get(`${url}/recipe/getList/search/${page}`, {
          params: { ...categoryFilter, ...searchFilter },
        })
        .then(result => {
          // console.log(result.data);
          setDataList(result.data);
        });
    }
  }, [page, refreshSwitch]);

  useEffect(() => {
    // 검색어가 없는 경우 => 필터로만 검색
    if (searchFilter.searchInput === '') {
      axios.get(`${url}/recipe/getTotalNum/normal`, { params: categoryFilter }).then(result => {
        // console.log(result.data.totalNum);
        setTotalItem(result.data.totalNum);
      });
      // 검색어가 없는 경우 => 필터 + 검색어
    } else {
      axios
        .get(`${url}/recipe/getTotalNum/search`, {
          params: { ...categoryFilter, ...searchFilter },
        })
        .then(result => {
          // console.log(result.data.totalNum);
          setTotalItem(result.data.totalNum);
        });
    }
  }, [refreshSwitch]);
}

/** 레시피 상세 정보 조회(레시피 정보 & 레시피 상세 싹) */
export function recipeDetailHook(
  editRecipeId,
  setRecipeInfoData,
  setRecipeDetailList,
  setRecipeDetailOgList,
) {
  if (editRecipeId !== 0) {
    useEffect(() => {
      axios.get(`${url}/recipe/getDetail/recipeInfo/${editRecipeId}`).then(result => {
        // console.log(result.data[0], '인포');
        setRecipeInfoData(result.data[0]);
      });
    }, [editRecipeId]);

    useEffect(() => {
      axios.get(`${url}/recipe/getDetail/recipeDetail/${editRecipeId}`).then(result => {
        // console.log(result.data, '디테일');
        setRecipeDetailList(result.data);
        setRecipeDetailOgList(result.data);
      });
    }, [editRecipeId]);
  }
}

/** 레시피 출력폼 훅 (계산 등 포함) */
export function CoaFormHook(recipeId, setRecipeInfoData, setRecipeDetailList) {
  if (recipeId !== 0) {
    useEffect(() => {
      axios.get(`${url}/recipe/getDetail/recipeInfo/${recipeId}`).then(result => {
        // console.log(result.data[0], '인포');
        setRecipeInfoData(result.data[0]);
      });
    }, [recipeId]);

    useEffect(() => {
      axios.get(`${url}/recipe/getDetail/coaForm/${recipeId}`).then(result => {
        // console.log(result.data, 'coaForm');
        setRecipeDetailList(result.data);
      });
    }, [recipeId]);
  }
}

// ㅇ 원자재
/** 원자재 검색 */
export function materialSearchHook(
  table,
  searchFilter,
  sortField,
  sortType,
  setDataList,
  page,
  setTotalItem,
  refreshSwitch,
) {
  useEffect(() => {
    // 검색어가 없는 경우 => 필터로만 검색
    if (searchFilter.searchInput === '') {
      // console.log(sortField);
      axios
        .get(`${url}/material/${table}/getList/normal/${sortField}/${sortType}/${page}`)
        .then(result => {
          // console.log(result.data);
          setDataList(result.data);
        });
      // 검색값이 있는 경우 필터 + 검색어
    } else {
      axios
        .get(`${url}/material/${table}/getList/search/${sortField}/${sortType}/${page}`, {
          params: { ...searchFilter },
        })
        .then(result => {
          // console.log(result.data);
          setDataList(result.data);
        });
    }
  }, [page, refreshSwitch, sortField, sortType]);

  useEffect(() => {
    // 검색어가 없는 경우 => 필터로만 검색
    if (searchFilter.searchInput === '') {
      axios.get(`${url}/material/${table}/getTotalNum/normal`).then(result => {
        // console.log(result.data.totalNum);
        setTotalItem(result.data.totalNum);
      });
      // 검색어가 없는 경우 => 필터 + 검색어
    } else {
      axios
        .get(`${url}/material/${table}/getTotalNum/search`, {
          params: { ...searchFilter },
        })
        .then(result => {
          // console.log(result.data.totalNum);
          setTotalItem(result.data.totalNum);
        });
    }
  }, [refreshSwitch]);
}

/** 원자재 상세 정보 조회(원자재 정보 및 원자재 구성물질) */
export function compositionListHook(
  editMaterialId,
  setMaterialInfoData,
  setCompositionList,
  setCompositionOgList,
) {
  if (editMaterialId !== 0) {
    useEffect(() => {
      axios.get(`${url}/material/getDetail/materialInfo/${editMaterialId}`).then(result => {
        // console.log(result.data[0], '원자재 정보');
        setMaterialInfoData(result.data[0]);
      });
    }, [editMaterialId]);

    useEffect(() => {
      axios.get(`${url}/material/getDetail/composition/${editMaterialId}`).then(result => {
        // console.log(result.data, '구성 물질');
        setCompositionList(result.data);
        setCompositionOgList(result.data);
      });
    }, [editMaterialId]);
  }
}

/** 구성물질 상세 정보 조회 */
export function compositionDetailHook(editCompositionId, setCompositionData) {
  if (editCompositionId !== 0) {
    useEffect(() => {
      axios.get(`${url}/material/getDetail/compositionDetail/${editCompositionId}`).then(result => {
        // console.log(result.data[0], '원자재 세부 정보');
        setCompositionData(result.data[0]);
      });
    }, [editCompositionId]);
  }
}

/** 월별 사용량 조회 */
export function monthlyUsageDataHook(materialId, setUsageDataList) {
  useEffect(() => {
    axios.get(`${url}/material/monthlyUsage/getList/${materialId}`).then(result => {
      // console.log(result.data, 'usageData');
      setUsageDataList(result.data);
    });
  }, [materialId]);
}

/** 원료 증감추이 조회 */
export function inventoryHistoryHook(materialId, setInventoryDataList, page, setTotalItem) {
  useEffect(() => {
    axios.get(`${url}/material/inventoryHistory/getList/${materialId}/${page}`).then(result => {
      // console.log(result.data, 'inventory');
      setInventoryDataList(result.data);
    });
  }, [materialId, page]);

  useEffect(() => {
    axios.get(`${url}/material/inventoryHistory/getTotalNum/${materialId}`).then(result => {
      // console.log(result.data, 'inventory');
      setTotalItem(result.data.totalNum);
    });
  }, [materialId]);
}

// ㅇ 생산기록
/** 생산 기록 검색 */
export function historySearchHook(searchFilter, setDataList, page, setTotalItem, refreshSwitch) {
  useEffect(() => {
    // 검색어가 없는 경우 => 필터로만 검색
    if (searchFilter.searchInput === '') {
      axios.get(`${url}/production/history/getList/normal/${page}`).then(result => {
        // console.log(result.data);
        setDataList(result.data);
      });
      // 검색값이 있는 경우 필터 + 검색어
    } else {
      axios
        .get(`${url}/production/history/getList/search/${page}`, {
          params: { ...searchFilter },
        })
        .then(result => {
          // console.log(result.data);
          setDataList(result.data);
        });
    }
  }, [page, refreshSwitch]);

  useEffect(() => {
    // 검색어가 없는 경우 => 필터로만 검색
    if (searchFilter.searchInput === '') {
      axios.get(`${url}/production/history/getTotalNum/normal`).then(result => {
        // console.log(result.data.totalNum);
        setTotalItem(result.data.totalNum);
      });
      // 검색어가 없는 경우 => 필터 + 검색어
    } else {
      axios
        .get(`${url}/production/history/getTotalNum/search`, {
          params: { ...searchFilter },
        })
        .then(result => {
          // console.log(result.data.totalNum);
          setTotalItem(result.data.totalNum);
        });
    }
  }, [refreshSwitch]);
}

/** 생산현황 */
export function statusDataHook(statusList, setDataList) {
  const [refreshSwitch, setRefreshSwitch] = useState(true);

  useEffect(() => {
    axios.get(`${url}/production/status/getList`).then(result => {
      // console.log(result.data, '생산현황');
      const tempData = [];
      statusList.forEach(eachStatus => {
        tempData.push(result.data.filter(each => each.currentState === eachStatus.label));
      });
      setDataList(tempData);
    });
  }, [refreshSwitch]);

  return { refreshSwitch, setRefreshSwitch };
}

/** 작업완료 내역서 */
export function workCompletionHook(productionId, setDataList, setRecipeDetailList) {
  useEffect(() => {
    axios.get(`${url}/production/workCompletion/${productionId}`).then(result => {
      // console.log(result.data[0]);
      setDataList(result.data[0]);
    });
  }, [productionId]);

  useEffect(() => {
    axios.get(`${url}/production/workRecipeDetail/${productionId}`).then(result => {
      setRecipeDetailList(result.data);
    });
  }, [productionId]);
}

/** 레시피 출력폼 훅 (계산 등 포함) */
export function recipeFormHook(recipeId, setRecipeInfoData, setRecipeDetailList) {
  if (recipeId !== 0) {
    useEffect(() => {
      axios.get(`${url}/recipe/getDetail/recipeInfo/${recipeId}`).then(result => {
        // console.log(result.data[0], '인포');
        setRecipeInfoData(result.data[0]);
      });
    }, [recipeId]);

    useEffect(() => {
      axios.get(`${url}/recipe/getDetail/recipeFrom/${recipeId}`).then(result => {
        // console.log(result.data, '디테일');
        setRecipeDetailList(result.data);
      });
    }, [recipeId]);
  }
}

// ㅇ 유저
/** 유저 정보 목록 */
export function userDataHook(setDataList, refreshSwitch) {
  useEffect(() => {
    axios.get(`${url}/user/getList`).then(result => {
      // console.log(result.data, 'userList');
      setDataList(result.data);
    });
  }, [refreshSwitch]);
}
