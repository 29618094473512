import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import { FixedBodyContainer } from './component/style/ContainerStyle';
import './App.css';
import NavBar from './component/navbar';
import Dashboard from './pages/Dashboard';
import Recipe from './pages/Recipe';
import User from './pages/User';
import Instruction from './pages/Production/Instruction';
import Status from './pages/Production/Status';
import History from './pages/Production/History';
import SubNavBar from './component/navbar/SubNavBar';
import Composition from './pages/Material/Composition';
import Additive from './pages/Material/Additive';
import Entrance from './pages/Entrance';
import { LoginContext } from './module/ContextAPI';

function App() {
  const { userAuthority } = useContext(LoginContext);
  // console.log(userAuthority);

  return (
    <div className="App">
      {userAuthority === '로그인X' ? (
        <Entrance />
      ) : (
        <Container disableGutters maxWidth={false} sx={{ backgroundColor: '#F6F8FF' }}>
          <NavBar />
          <Box sx={{ height: '55px' }} />
          <SubNavBar />
          <FixedBodyContainer>
            <Routes>
              {/* 대시보드 */}
              <Route path="/" element={<Dashboard />} />

              {/* 생산 */}
              <Route path="/Production/Instruction" element={<Instruction />} />
              <Route path="/Production/Status" element={<Status />} />
              <Route path="/Production/History" element={<History />} />

              {/* 레시피 */}
              <Route path="/Recipe" element={<Recipe />} />

              {/* 원자재 */}
              <Route path="/Material/Additive" element={<Additive />} />
              <Route path="/Material/Composition" element={<Composition />} />

              {/* 회원 관리 */}
              <Route path="/User" element={<User />} />
            </Routes>
          </FixedBodyContainer>
        </Container>
      )}
    </div>
  );
}

export default App;
