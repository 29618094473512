import React, { useState } from 'react';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Stack, TextField } from '@mui/material';
import { url } from '../commonVariable';

export default function PasswordChangeModal({ modalSwitch, setModalSwitch }) {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const onChangePassowrd = e => {
    setPassword(e.target.value);
  };

  const onChangePassowrdConfirm = e => {
    setPasswordConfirm(e.target.value);
  };

  const modalClose = () => setModalSwitch(false);

  const changePassword = () => {
    if (!password) {
      alert('변경할 비밀번호를 입력해주세요!');
    } else if (password !== passwordConfirm) {
      alert('변경할 비밀번호와 비밀번호 확인이 서로 다릅니다');
    } else {
      const body = { newPassword: password };
      axios.post(`${url}/user/account/changePassword/reqUser`, body).then(() => {
        alert('비밀번호가 변경되었습니다.');
        modalClose();
      });
    }
  };

  return (
    <div>
      <Modal open={modalSwitch} onClose={modalClose}>
        <Box
          sx={{
            width: 350,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#F8F8F8',
            border: '1px solid #B8B8B8;',
            borderRadius: '4px',
            p: '30px 40px 30px 40px',
            outline: 'none',
          }}
        >
          <Typography variant="h4" color="#333333" align="center" fontWeight={600} fontSize="26px">
            <Box sx={{ mb: '20px' }}>비밀번호 변경</Box>
          </Typography>

          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing="20px">
            <Box sx={{ width: '180px' }}>변경할 비밀번호</Box>
            <form>
              <TextField
                fullWidth
                value={password}
                onChange={onChangePassowrd}
                type="password"
                autoComplete="false"
              />
            </form>
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing="20px"
            sx={{ mt: '10px' }}
          >
            <Box sx={{ width: '180px' }}>비밀번호 확인</Box>
            <form>
              <TextField
                fullWidth
                value={passwordConfirm}
                onChange={onChangePassowrdConfirm}
                type="password"
                autoComplete="false"
              />
            </form>
          </Stack>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '25px' }}>
            <Button
              variant="contained"
              onClick={changePassword}
              sx={{ width: '100px', height: '34px', fontSize: '18px', mr: '10px' }}
            >
              확인
            </Button>
            <Button
              variant="contained"
              color="cancel"
              onClick={modalClose}
              sx={{ width: '100px', height: '34px', fontSize: '18px', color: '#FFF' }}
            >
              취소
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

PasswordChangeModal.defaultProps = {
  modalSwitch: true,
  setModalSwitch: () => {},
};

PasswordChangeModal.propTypes = {
  modalSwitch: PropTypes.bool,
  setModalSwitch: PropTypes.func,
};
