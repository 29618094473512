import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from './ContextAPI';

export function LoginCheck() {
  const { userAuthority } = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userAuthority === '로그인X') {
      navigate('/Login');
    }
  }, []);

  return null;
}

export function pageAuthorityCheck() {
  const { userAuthority } = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userAuthority === 'A' || userAuthority === 'B') {
      // console.log('O');
    } else {
      alert('접근 권한이 없습니다!');
      navigate('/');
    }
  }, []);
}
