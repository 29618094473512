import React, { useState } from 'react';
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Stack,
} from '@mui/material';
import { MainTitle } from '../../../component/style/TextStyle';
import { SearchFilter } from '../../../component/ui/SearchFilter';
import { StyledRow, StyledCell } from '../../../component/style/TableStyle';
import { compositionNumber } from '../../../component/commonVariable';
import Pagination from '../../../component/Pagination';
import { materialSearchHook } from '../../../module/useEffectHooks';
import CompositionAddEditModal from './CompositionAddEditModal';
import { changeDateDot, downloadAll } from '../../../component/commonFunction';
import { pageAuthorityCheck } from '../../../module/LoginCheck';

export default function Composition() {
  pageAuthorityCheck();

  const searchField = ['Cas No', '이름'];
  const [searchFilter, setSearchFilter] = useState({
    searchFilter: 'Cas No',
    searchInput: '',
  });

  const dataTable = [
    'Cas No',
    '이름',
    '알러지 유발 유무',
    '유해화학물질 기준',
    '메모',
    '최종수정',
    '수정',
  ];

  // 첨가물 데이터 및 페이지
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(10);

  // 원자재 수정
  const [addEditModalSwitch, setAddEditModalSwitch] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editCompositionId, setEditCompositionId] = useState(0);

  const [refreshSwitch, setRefreshSwitch] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  materialSearchHook(
    'composition',
    searchFilter,
    'Id',
    '▼',
    setDataList,
    page,
    setTotalItem,
    refreshSwitch,
  );

  const searchFilterSearch = () => {
    setRefreshSwitch(!refreshSwitch);
  };

  const openAddEditModal = (mode, id) => {
    if (mode === 'add') {
      setIsEditMode(false);
      setEditCompositionId(0);
    } else if (mode === 'edit') {
      setEditCompositionId(id);
      setIsEditMode(true);
    }
    setAddEditModalSwitch(true);
  };

  // 데이터 다운로드
  const download = async () => {
    try {
      if (isLoading) return;
      await downloadAll('/material/composition/download', '구성물질 목록', setIsLoading);
    } catch (error) {
      alert(`다운로드에 실패했습니다\n${error}`);
    }
  };

  return (
    <div>
      <SearchFilter
        selectList={searchField}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        searchFilterSearch={searchFilterSearch}
      />

      <Grid container direction="row" justifyContent="space-between" alignContent="center">
        <MainTitle>구성물질 리스트</MainTitle>

        <Stack direction="row" spacing={1}>
          <Button variant="outlined" onClick={download} sx={{ width: '80px', height: '30px' }}>
            {isLoading ? '다운로드중' : 'Export'}
          </Button>
          <Button onClick={() => openAddEditModal('add')} sx={{ width: '90px', height: '30px' }}>
            항목 추가
          </Button>
        </Stack>
      </Grid>

      <TableContainer component={Paper} sx={{ maxHeight: '480px', mt: '5px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {dataTable.map(each => (
                <StyledCell key={each}>{each}</StyledCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {dataList.map(each => (
              <StyledRow key={each.compositionId}>
                <StyledCell>{each.casNum}</StyledCell>
                <StyledCell>{each.name}</StyledCell>
                <StyledCell>{each.alergy}</StyledCell>
                <StyledCell>{each.hazardousStandard}</StyledCell>
                <StyledCell>{each.note}</StyledCell>
                <StyledCell>{`${each.lastEditor} / ${changeDateDot(each.updatedAt)}`}</StyledCell>
                <StyledCell>
                  <Button
                    onClick={() => openAddEditModal('edit', each.compositionId)}
                    variant="outlined"
                    sx={{ width: '70px' }}
                  >
                    수정하기
                  </Button>
                </StyledCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        page={page}
        setPage={setPage}
        totalItem={totalItem}
        itemNumber={compositionNumber}
      />

      {addEditModalSwitch && (
        <CompositionAddEditModal
          modalSwitch={addEditModalSwitch}
          setModalSwitch={setAddEditModalSwitch}
          refreshSwitch={refreshSwitch}
          setRefreshSwitch={setRefreshSwitch}
          isEditMode={isEditMode}
          editCompositionId={editCompositionId}
        />
      )}
    </div>
  );
}
